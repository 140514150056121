import React from "react";
import { useDispatch } from "react-redux";
import Button from "../../elements/Button";
import ButtonType from "../../elements/ButtonType";
import ButtonFeel from "../../elements/ButtonFeel";
import Icon from "../../elements/Icon";
import savePost from "./savePost";
import saveGallery from "./saveGallery";

const Save = ({ post }) => {
  const dispatch = useDispatch();

  const save = e => {
    savePost(post, dispatch);
    saveGallery(post, dispatch);
  };

  return (
    <Button
      type={ButtonType.Fab}
      feel={ButtonFeel.Primary}
      title="Save post"
      action={save}
      inProgress={post.isLoading}
    >
      <Icon icon={post.isLoading ? "hourglass_empty" : "save"} />
    </Button>
  );
};

export default Save;

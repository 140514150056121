import React from "react";
import NavigationButton from "./NavigationButton";

export const getSettings = (numberOfSlides, className) => {
  return {
    // adaptiveHeight: true, // TODO : this is causing some trouble

    // dots: true,
    // infinite: true, // TODO : this clones slides and thus input in edit mode fields etc
    infinite: false,
    speed: 500,
    slidesToShow: Math.min(3, numberOfSlides),
    slidesToScroll: 1,
    className: className,

    draggable: false,
    // dotsClass: styles.dots, // TODO : change the styles

    nextArrow: <NavigationButton goForward={true} />,
    prevArrow: <NavigationButton goForward={false} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(3, numberOfSlides)
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: Math.min(2, numberOfSlides)
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: Math.min(1, numberOfSlides)
        }
      }
    ]
  };
};

export const getFullscreenSettings = className => {
  return {
    // adaptiveHeight: true, // TODO : this is causing some trouble

    // dots: true,
    // infinite: true, // TODO : this clones slides and thus input in edit mode fields etc
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: className,

    // lazyLoad: true,
    // useTransform: false,
    // dotsClass: styles.dots, // TODO : change the styles

    nextArrow: <NavigationButton goForward={true} />,
    prevArrow: <NavigationButton goForward={false} />
  };
};

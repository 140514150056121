import React from "react";
import cx from "classnames";
import styles from "./LayoutFooter.module.css";
import { useTranslation } from "react-i18next";

const LayoutFooter = () => {
  const { t } = useTranslation();

  return (
    <footer className={cx("mdl-mini-footer", styles.container)}>
      <div className="mdl-mini-footer--left-section">
        &copy; {new Date().getFullYear()}, {t("site.footer")}
      </div>
      <div className="mdl-mini-footer--right-section"></div>
    </footer>
  );
}

export default LayoutFooter;

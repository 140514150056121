import React, { useEffect, useState } from "react";
import Button from "../../elements/Button";
import ButtonType from "../../elements/ButtonType";
import ButtonFeel from "../../elements/ButtonFeel";
import Icon from "../../elements/Icon";
import { createMediaLibrary } from "../../../utils/cloudinary";

// NOTE: these are not defined inline because they would have to be memoized for useEffect dependency array
const DefaultOnSelectionFunc = _asset => { alert("This is not meant for selection, just upload.") };
const DefaultLibraryOptions = {}

const CloudinaryMediaLibrary = ({
  className,
  title = "Select photo",
  icon = "folder_open",
  onSelection = DefaultOnSelectionFunc,
  options = DefaultLibraryOptions
}) => {

  const [library, setLibrary] = useState(null);

  useEffect(() => {
    const handler = function (data) {
      data.assets.forEach(asset => { 
          onSelection(asset)
      })
    }
  
    const lib = createMediaLibrary(options, handler)

    setLibrary(lib)
  }, [onSelection, options, setLibrary])

  return (
    <Button
      type={ButtonType.Fab}
      feel={ButtonFeel.Accent}
      title={title}
      className={className}
      action={library?.showMediaLibrary(options)}
    >
      <Icon icon={icon} />
    </Button>
  );
};

export default CloudinaryMediaLibrary;

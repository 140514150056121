import uuid from "react-uuid";
export const ADD_SLIDE = "ADD_SLIDE";
export const DELETE_SLIDE = "DELETE_SLIDE";
export const MOVE_SLIDE = "MOVE_SLIDE";

export const ADD_GALLERY_SUCCESS = "ADD_GALLERY_SUCCESS";
export const ADD_GALLERY_FAILURE = "ADD_GALLERY_FAILURE";
export const FETCH_GALLERY = "FETCH_GALLERY";
export const FETCH_GALLERY_SUCCESS = "FETCH_GALLERY_SUCCESS";
export const FETCH_GALLERY_FAILURE = "FETCH_GALLERY_FAILURE";
export const GALLERY_TOGGLE_IS_PUBLISHED = "GALLERY_TOGGLE_IS_PUBLISHED";

export const SLIDE_STASH_CHANGES = "SLIDE_STASH_CHANGES";
export const SLIDE_PERSIST = "SLIDE_PERSIST";
export const SLIDE_PERSIST_SUCCESS = "SLIDE_PERSIST_SUCCESS";
export const SLIDE_PERSIST_FAILURE = "SLIDE_PERSIST_FAILURE";

export const SLIDE_DATA_ADD = "SLIDE_DATA_ADD"
export const SLIDE_DATA_STAGE_CHANGE = "SLIDE_DATA_STAGE_CHANGE";
export const SLIDE_DATA_FETCH_SUCCESS = "SLIDE_DATA_FETCH_SUCCESS";

export const slideDataAdd = slideData => ({
  type: SLIDE_DATA_ADD,
  payload: slideData
});
export const slideDataStageChange = slideData => ({
  type: SLIDE_DATA_STAGE_CHANGE,
  payload: slideData
});
export const slideDataPersistSuccess = slideData => ({
  type: SLIDE_DATA_FETCH_SUCCESS,
  payload: slideData
});

export const addSlide = (galleryId, imgUrl, multilingual) => {
  return {
    type: ADD_SLIDE,
    payload: {
      tempId: uuid(),
      isNew: true,
      photoAlbumId: galleryId,
      multilingual,
      order: 1,
      fullsize: imgUrl,
      thumbnail: imgUrl
    }
  };
};

export const deleteSlide = (slideId, galleryId) => {
  return {
    type: DELETE_SLIDE,
    payload: {
      id: slideId,
      photoAlbumId: galleryId
    }
  };
};

export const moveSlide = (slideId, galleryId, order, delta) => {
  return {
    type: MOVE_SLIDE,
    payload: {
      id: slideId,
      photoAlbumId: galleryId,
      order,
      delta
    }
  };
};

export const addGallerySuccess = gallery => {
  return {
    type: ADD_GALLERY_SUCCESS,
    payload: { ...gallery, isNew: false }
  };
};

export const addGalleryFailure = error => {
  return {
    type: ADD_GALLERY_FAILURE,
    payload: error
  };
};

export const fetchGallery = () => {
  return {
    type: FETCH_GALLERY
  };
};

export const fetchGallerySuccess = gallery => {
  return {
    type: FETCH_GALLERY_SUCCESS,
    payload: gallery
  };
};

export const fetchGalleryFailure = error => {
  return {
    type: FETCH_GALLERY_FAILURE,
    payload: error
  };
};

export const galleryToggleIsPublished = (galleryId, isPublished) => {
  return {
    type: GALLERY_TOGGLE_IS_PUBLISHED,
    payload: { id: galleryId, isPublished }
  };
};

export const stashSlideChanges = slide => {
  return {
    type: SLIDE_STASH_CHANGES,
    payload: slide
  };
};

export const persistSlide = () => {
  return {
    type: SLIDE_PERSIST
  };
};

export const persistSlideSuccess = slide => {
  return {
    type: SLIDE_PERSIST_SUCCESS,
    payload: slide
  };
};

export const persistSlideFailure = error => {
  return {
    type: SLIDE_PERSIST_FAILURE,
    payload: error
  };
};

import postReducer from "./post-reducer";
import {
  BLOG_POST_ADD,
  BLOG_POST_DELETE,
  BLOG_FETCH_MORE,
  BLOG_POSTS_FETCH
} from "../actions/posts-actions";
import {
  POST_TOGGLE_IS_PUBLISHED,
  POST_PERSIST_SUCCESS,
  POST_FETCH_SUCCESS,
  POST_EDIT,
  POST_STAGE_CHANGE,
  POST_DATA_ADD,
  POST_DATA_STAGE_CHANGE
} from "../actions/post-actions";
import {
  FETCH_GALLERY_SUCCESS,
  ADD_SLIDE,
  SLIDE_PERSIST_SUCCESS,
  SLIDE_STASH_CHANGES,
  DELETE_SLIDE,
  ADD_GALLERY_SUCCESS,
  MOVE_SLIDE,
  GALLERY_TOGGLE_IS_PUBLISHED,
  SLIDE_DATA_STAGE_CHANGE,
  SLIDE_DATA_ADD
} from "../actions/gallery-actions";

const initialState = {
  items: [],
  nextToken: null
};

const postsReducer = (state = initialState, { type, payload }) => {
  console.log("POSTS REDUCER: " + type);
  switch (type) {
    case BLOG_POST_ADD:
      return {
        ...state,
        items: [payload].concat(state.items)
      };
    case BLOG_POST_DELETE:
      return {
        ...state,
        items: state.items.filter(p => p.id !== payload)
      };
    case BLOG_POSTS_FETCH:
      return {
        items: payload.items,
        nextToken: payload.nextToken,
        initialized: true
      };
    case BLOG_FETCH_MORE:
      return {
        ...state,
        items: state.items.concat(payload.posts),
        nextToken: payload.nextToken
      };

    case POST_FETCH_SUCCESS:
      return {
        ...state,
        // items: [payload, ...state.items.filter(val => val.id !== payload.id)]
        items: [...state.items, payload]
      };

    case POST_DATA_ADD:
    case POST_DATA_STAGE_CHANGE:
    case ADD_SLIDE:
    case DELETE_SLIDE:
    case MOVE_SLIDE:
    case POST_EDIT:
    case ADD_GALLERY_SUCCESS:
    case FETCH_GALLERY_SUCCESS:
    case GALLERY_TOGGLE_IS_PUBLISHED:
    case POST_STAGE_CHANGE:
    case POST_PERSIST_SUCCESS:
    case POST_TOGGLE_IS_PUBLISHED:
    case SLIDE_PERSIST_SUCCESS:
    case SLIDE_STASH_CHANGES:
    case SLIDE_DATA_ADD:
    case SLIDE_DATA_STAGE_CHANGE:
      return {
        ...state,
        items: state.items.map(post => postReducer(post, { type, payload }))
      };

    default:
      return state;
  }
};

export default postsReducer;

import React from "react";
import styles from "./SlideViewPhoto.module.css";
import useMultilingualValue from "../../../custom-hooks/useMultilingualValue";
import { settings } from "../../../utils/cloudinary"
import { Image, Transformation, Placeholder } from 'cloudinary-react';

const SlideViewPhoto = ({ slide }) => {
  const caption = useMultilingualValue(slide, "caption");
  return (
    <div className={styles.wrapper}>
      <figure className={styles.figure}>
        <picture>
          <Image
            cloudName={settings.cloud_name}
            publicId={slide.fullsize} 
            dpr="auto"
            width="400"
            crop="scale"
            responsiveUseBreakpoints="true"
            className={styles.picture}
            alt={caption}
            loading="lazy"
          >
            <Transformation quality="auto" fetchFormat="auto" />
            <Placeholder type="predominant" />
          </Image>
        </picture>
        <figcaption className={styles.caption}>
          {caption}
        </figcaption>
      </figure>
    </div>
  );
};

export default SlideViewPhoto;

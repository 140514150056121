// TODO : don't load whole app.css, now we have styles just for supporting text on the card
// TODO : fix font import
// eslint-disable-next-line import/no-webpack-loader-syntax
import app_css from "!!raw-loader!../../App.css";
import setupEditor from "../../utils/tinymce-editor-setup";

export const mceApiKey = "ckdm0orwps8xvlbvv618007rmrwv32ygnpxb0uxnz4gz6yzz";

export const defaultEditorSettings = {
  height: 200,
  menubar: false,
  // branding: false,
  body_class:
    "mce-content-body mdl-card mdl-cell mdl-cell--12-col mdl-card__supporting-text editor-body",
  content_css: "https://code.getmdl.io/1.3.0/material.grey-blue.min.css",
  content_style: app_css,
  plugins: "lists link media emoticons code", // importcss // TODO : not useful until I import App.css
  toolbar: `undo redo 
			| styleselect 
			| bold italic removeformat 
			| forecolor
			| alignleft aligncenter alignright alignjustify 
			| bullist numlist outdent indent 
			| emoticons link cloudinary-image media code`,
  // | fontsizeselect
  // fontsize_formats: '11px 12px 14px 16px 18px 24px 36px 48px',
  relative_urls: false,
  imagetools_cors_hosts: ["blog-verca-dev.s3.eu-central-1.amazonaws.com"], // TODO : use aws_content_delivery_url from aws config?
  style_formats: [
    {
      title: "Headings",
      items: [
        { title: "Heading 1", format: "h1" },
        { title: "Heading 2", format: "h2" },
        { title: "Heading 3", format: "h3" },
        { title: "Heading 4", format: "h4" },
        { title: "Heading 5", format: "h5" },
        { title: "Heading 6", format: "h6" }
      ]
    },
    {
      title: "Inline",
      items: [
        { title: "Bold", format: "bold" },
        { title: "Italic", format: "italic" },
        { title: "Underline", format: "underline" },
        { title: "Strikethrough", format: "strikethrough" },
        { title: "Superscript", format: "superscript" },
        { title: "Subscript", format: "subscript" },
        { title: "Code", format: "code" }
      ]
    },
    {
      title: "Blocks",
      items: [
        { title: "Paragraph", format: "p" },
        { title: "Blockquote", format: "blockquote" },
        { title: "Div", format: "div" },
        { title: "Pre", format: "pre" }
      ]
    },
    {
      title: "Custom",
      items: [
        { title: "Post intro", inline: "span", classes: "post-intro-text" },
        { title: "My tip", inline: "span", classes: "my-tip" },
        { title: "My disclaimer", inline: "span", classes: "my-disclaimer" },
        { title: "My footnote", inline: "span", classes: "my-footnote" },
        { title: "Img text", inline: "span", classes: "img-text" }
      ]
    }
  ],
  setup: setupEditor
};

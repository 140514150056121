let lastScroll = 0;

const scrollHelper = (
  scrollingElement,
  scrollUpClassName,
  scrollDownClassName
) => {
  const currentScroll = scrollingElement.scrollTop;

  if (currentScroll === 0) {
    scrollingElement.classList.remove(scrollUpClassName);
    scrollingElement.classList.remove(scrollDownClassName);
    return;
  }

  if (
    currentScroll > lastScroll &&
    !scrollingElement.classList.contains(scrollDownClassName)
  ) {
    scrollingElement.classList.remove(scrollUpClassName);
    scrollingElement.classList.add(scrollDownClassName);
  } else if (
    currentScroll < lastScroll &&
    scrollingElement.classList.contains(scrollDownClassName)
  ) {
    scrollingElement.classList.remove(scrollDownClassName);
    scrollingElement.classList.add(scrollUpClassName);
  }
  lastScroll = currentScroll;
};

export default scrollHelper;

import React, { useRef } from "react";
import styles from "./PostTitleInput.module.css";

const PostTitleInput = ({ value, onChange }) => {
  const defaultValue = useRef(value);
  const onTitleChange = e => {
    onChange({ title: e.nativeEvent.target.innerText });
  };
  
  return (
    <h3
      contentEditable="true"
      suppressContentEditableWarning="true"
      onInput={onTitleChange}
      className={styles.textarea}
    >
      {defaultValue.current}
    </h3>
  );
};

export default PostTitleInput;

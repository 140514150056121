import React, { useEffect } from "react";
import { postStageChange, postDataStageChange } from "../../actions/post-actions";
import EditableTagList from "../tags/EditableTagList";
import LayoutSpacer from "../layout/LayoutSpacer";
import PostInfoEditable from "./PostInfoEditable";
import PostMetadata from "./PostMetadata";
import PostEditor from "./PostEditor";
import PostMedia from "./PostMedia";
import PostCard from "./PostCard";
import PostMediaInput from "./PostMediaInput";
import PostTitleInput from "./PostTitleInput";
import { useDispatch } from "react-redux";
import useMultilingualValue from "../../custom-hooks/useMultilingualValue";
import { useTranslation } from "react-i18next";
import debounce from "../../utils/debounce";

function PostCardEdit({ post }) {
  // TODO : fix MDL elements like input, checkbox and switch
  // TODO : see section about dynamic elements in their docs
  useEffect(() => {
    window.componentHandler.upgradeDom();
  });

  const debounceTimeMs = 500;
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  
  const stageChange = change => {
    const newPost = { ...post, ...change };
    dispatch(postStageChange(newPost));
  };
  const multilingualStageChange = change => {
    const data = post?.multilingual?.items.find(i => i.lang === i18n.language)
    const newPostData = { id: data.id, ...change };
    dispatch(postDataStageChange(newPostData));
  };

  return (
    <PostCard>
      <PostMedia imageUrl={post.bannerImg}>
        <PostMediaInput onChange={stageChange} />
        <PostTitleInput
          name="title"
          value={useMultilingualValue(post, "title")}
          placeholder="Title..."
          onChange={debounce(multilingualStageChange, debounceTimeMs)}
        />
      </PostMedia>
      <PostMetadata>
        <PostInfoEditable
          author={post.author}
          publishedAt={post.publishedAt}
          onChange={stageChange}
        />
        <LayoutSpacer />
        <EditableTagList tags={post.tags} onChange={stageChange} />
      </PostMetadata>
      <PostEditor 
        name="intro" 
        value={useMultilingualValue(post, "intro")} 
        onChange={debounce(multilingualStageChange, debounceTimeMs)} />
      <PostEditor
        name="content"
        value={useMultilingualValue(post, "content")}
        onChange={debounce(multilingualStageChange, debounceTimeMs)}
        settings={{ height: 500 }}
      />
    </PostCard>
  );
}

export default PostCardEdit;

import React from "react";
import styles from "./SlideAddPhoto.module.css";
import CloudinaryMediaLibrary from "../actions/CloudinaryMediaLibrary";
import { addSlide } from "../../../actions/gallery-actions";
import { useDispatch } from "react-redux";
import PublishToggle from "./PublishToggle";
import { LOCALE_EN, LOCALE_CZ } from "../../../i18n";
import uuid from "react-uuid";

const SlideAddPhoto = ({ gallery }) => {
  const dispatch = useDispatch();

  const libraryOptions = {
    insert_caption: 'Add to gallery'
  }

  const onPhotoSelected = asset => {
    const multilingual = {items: [
      {
        id: uuid(),
        lang: LOCALE_EN,
        caption: "Caption"
      },
      {
        id: uuid(),
        lang: LOCALE_CZ,
        caption: "Popisek"
      }
    ]}

    dispatch(addSlide(gallery.id, asset.public_id, multilingual));
  };

  return (
    <div className={styles.container}>
      <div className={styles.buttonGroup}>
        <CloudinaryMediaLibrary onSelection={onPhotoSelected} options={libraryOptions} />
        <PublishToggle gallery={gallery} />
      </div>
    </div>
  );
};

export default SlideAddPhoto;

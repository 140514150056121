import React from "react";
import { withRouter } from "react-router";
import Avatar from "../Avatar";
import PostInfo from "./PostInfo";
import LayoutSpacer from "../layout/LayoutSpacer";
import TagList from "../tags/TagList";
import PostMetadata from "./PostMetadata";
import PostMedia from "./PostMedia";
import PostCard from "./PostCard";
import PostTitle from "./PostTitle";
import PostText from "./PostText";
import PostActionReadMore from "./PostActionReadMore";
import useMultilingualValue from "../../custom-hooks/useMultilingualValue";

const PostCardPreview = ({ post, match }) => {
  const postLink = `${match.url}/${post.id}`;
  return (
    <PostCard>
      <PostMedia imageUrl={post.bannerImg}>
        <PostTitle title={useMultilingualValue(post, "title")} link={postLink} />
        <PostActionReadMore link={postLink} />
      </PostMedia>
      <PostText text={useMultilingualValue(post, "intro")} />
      <PostMetadata>
        <Avatar />
        <PostInfo author={post.author} publishedAt={post.publishedAt} />
        <LayoutSpacer />
        <TagList tags={post.tags} />
      </PostMetadata>
    </PostCard>
  );
};

export default withRouter(PostCardPreview);

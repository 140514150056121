import React from "react";
import { TrackedButton } from "../../elements/Button";
import ButtonType from "../../elements/ButtonType";
import ButtonFeel from "../../elements/ButtonFeel";
import Icon from "../../elements/Icon";
import styles from "./NavigationButton.module.css";
import cx from "classnames";
import { useTranslation } from "react-i18next";

const NavigationButton = ({ goForward, onClick, ...props }) => {
  const { t } = useTranslation();

  return (
    <TrackedButton
      trackingLabel={goForward ? "gallery-photo-next" : "gallery-photo-previous"}
      type={ButtonType.Icon}
      feel={ButtonFeel.Accent}
      title={goForward ? t("pages.post.gallery.nextTitle") : t("pages.post.gallery.prevTitle")}
      action={onClick}
      className={cx(styles.button, goForward ? styles.right : styles.left)}
      inProgress={props.className.includes("disabled")}
    >
      <Icon icon={goForward ? "arrow_forward" : "arrow_back"} />
    </TrackedButton>
  );
};

export default NavigationButton;

import React from "react";
import CloudinaryMediaLibrary from "./CloudinaryMediaLibrary";

const UploadImage = ({ className }) => {

  return (
    <CloudinaryMediaLibrary 
      title="Upload photo"
      className={className}
      icon="add_photo_alternate"
    />
  );
};

export default UploadImage;

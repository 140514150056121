import React from "react";

export const showSnackbar = (data) => {
    try {
        const snackbarContainer = document.querySelector('#layout-snackbar');
        snackbarContainer.MaterialSnackbar.showSnackbar(data);
    } catch {}
}

const LayoutSnackbar = () => {
    return (
        <div id="layout-snackbar" className="mdl-js-snackbar mdl-snackbar">
            <div className="mdl-snackbar__text"></div>
            <button className="mdl-snackbar__action" type="button"></button>
        </div>        
    );
}

export default LayoutSnackbar;
import {
  SLIDE_STASH_CHANGES,
  SLIDE_PERSIST,
  SLIDE_PERSIST_SUCCESS,
  SLIDE_PERSIST_FAILURE,
  SLIDE_DATA_STAGE_CHANGE,
  SLIDE_DATA_ADD
} from "../actions/gallery-actions";
import photoDataReducer from "./photoDataReducer";

export const slideReducer = (state, { type, payload }) => {
  console.log("SLIDE REDUCER: " + type);

  switch (type) {
    case SLIDE_DATA_ADD:
      if (state.id !== payload.photoDataPhotoId) {
        return state;
      }
      return {
        ...state,
        multilingual: {
          ...state.multilingual,
          items: [payload].concat(state.multilingual.items ?? [])
        }
      }

    case SLIDE_DATA_STAGE_CHANGE:
      return {
        ...state,
        multilingual: {
          items: state.multilingual.items
            .map(multilingualItem => photoDataReducer(multilingualItem, { type, payload }))
        }
      };
    default:      
  }

  if (
    (state.isNew && state.tempId !== payload.tempId) ||
    (!state.isNew && state.id !== payload.id)
  )
  {
    return state;
  }

  switch (type) {
    case SLIDE_STASH_CHANGES:
      return {
        ...state,
        ...payload,
        isDirty: true
      };

    case SLIDE_PERSIST:
      return {
        ...state,
        isSaving: true
      };

    case SLIDE_PERSIST_SUCCESS:
      if (state.isDeleted) return null;
      return {
        ...state,
        ...payload,
        isNew: false,
        isDirty: false,
        isSaving: false,
        error: null
      };

    case SLIDE_PERSIST_FAILURE:
      return {
        ...state,
        isSaving: false,
        error: payload
      };

    default:
      return state;
  }
};

export default slideReducer;

import React from "react";
import Layout from "./components/layout/Layout.js";
import { Route, Switch, Redirect } from "react-router-dom";
import Auth from "./components/auth/auth-component.js";

import "./App.css";
// TODO : use webpack to bundle this?
import "dialog-polyfill/dist/dialog-polyfill.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BlogHelmet from "./components/blogs/BlogHelmet";
import usePageTracking from "./custom-hooks/usePageTracking";
import { useTranslation } from "react-i18next";

const App = () => {
  usePageTracking();
  const { i18n } = useTranslation();

  return (
    <div className="App">
      <BlogHelmet />
      <Switch>
        <Redirect exact path="/" to={`/${i18n.language}`} />
        <Redirect path="/blog" to={`/${i18n.language}`} />
        <Route path="/auth" component={Auth} />
        <Route path="/:lang" component={Layout} />
      </Switch>
    </div>
  );
}

export default App;

import { Cloudinary } from 'cloudinary-core';

export const settings = {
    cloud_name: 'dvelamysbatohem',
    api_key: '992522531835132',
    multiple: false,
    insert_caption: 'Insert',
    folder: { path: 'blog' }
}

const cloudinary = new Cloudinary({cloud_name: settings.cloud_name});

export const createMediaLibrary = (options, handler) => {
    
    const library = window.cloudinary.createMediaLibrary 
        && window.cloudinary.createMediaLibrary(
        {
            ...settings,
            ...options
        }, 
        {
            insertHandler: handler
        }
    );

    const showMediaLibrary = (options) => () => {
        library.show({
            folder: settings.folder,
            ...options
        }) 
    }

    return {
        showMediaLibrary
    }
}

export default cloudinary;
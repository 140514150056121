import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const Icon = ({ icon, className }) => {
  return (
    <i className={cx("material-icons", className)} role="presentation">
      {icon}
    </i>
  );
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired
};

export default Icon;

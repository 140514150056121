import React from "react";
import styles from "./SlideViewFullscreenPhoto.module.css";
import useMultilingualValue from "../../../custom-hooks/useMultilingualValue";
import { settings } from "../../../utils/cloudinary"
import { Image, Transformation, Placeholder } from 'cloudinary-react';

const SlideViewFullscreenPhoto = ({ slide }) => {
  const caption = useMultilingualValue(slide, "caption");

  return (
    <div className={styles.wrapper}>
      <figure className={styles.figure}>
        {/* TODO: define less breakpoints? */}
        <Image
          cloudName={settings.cloud_name}
          publicId={slide.fullsize} 
          dpr="auto"
          responsive
          width="auto"
          crop="scale"
          responsiveUseBreakpoints="true"
          className={styles.picture}
          alt={caption}
          loading="lazy"
        >
          <Transformation quality="auto" fetchFormat="auto" />
          <Placeholder type="predominant" />
          {/* TODO: llama overlay in the corner? */}
        </Image>
        <figcaption className={styles.caption}>
          {caption}
        </figcaption>
      </figure>
    </div>
  );
};

export default SlideViewFullscreenPhoto;

import React, { useState } from "react";
import { Auth as AwsAuth } from "aws-amplify";
import { Authenticator, SignUp } from "aws-amplify-react";
import { Redirect } from "react-router-dom";
import Helmet from "react-helmet";

const adminGroup = "blog-admin";
const editorGroup = "blog-editor";

export const isAuthorizedAsOwner = async (owner, user) => {
  try {
    const u = user || (await AwsAuth.currentAuthenticatedUser());
    return (
      u.username === owner ||
      u.signInUserSession.accessToken.payload["cognito:groups"].includes(
        adminGroup
      )
    );
  } catch (error) {
    return false;
  }
};

export const canViewUnpublishedBlogs = async (owner, user) => {
  try {
    const u = user || (await AwsAuth.currentAuthenticatedUser());
    return (
      u.username === owner ||
      u.signInUserSession.accessToken.payload["cognito:groups"].includes(
        adminGroup
      ) ||
      u.signInUserSession.accessToken.payload["cognito:groups"].includes(
        editorGroup
      )
    );
  } catch (error) {
    return false;
  }
};

const Auth = () => {
  const [authState, setAuthState] = useState("");

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Authenticator
        hide={[SignUp]}
        onStateChange={state => setAuthState(state)}
      >
        {authState === "signedIn" && <Redirect to="/" />}
      </Authenticator>
    </>
  );
};

export default Auth;

import React, { useState, useEffect } from "react";
import PostNavigationLink from "./PostNavigationLink";
import { canViewUnpublishedBlogs } from "../auth/auth-component";
import LayoutSpacer from "../layout/LayoutSpacer";
import Navigation from "../elements/Navigation";
import { useTranslation } from "react-i18next";

const PostNavigation = ({ post }) => {
  const [display, setDisplay] = useState(false);
  const [showUnpublished, setShowUnpublished] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const showUnpublishedAsync = async () => {
      const showUnpublished = await canViewUnpublishedBlogs(post.blog.owner);
      setShowUnpublished(showUnpublished);
      setDisplay(true);
    };
    showUnpublishedAsync();
  }, [post.blog.owner]);

  return (
    display && (
      <Navigation>
        <PostNavigationLink
          currentPost={post}
          showUnpublished={showUnpublished}
          getNewerPost={false}
          title={t("pages.post.navigation.prevTitle")}
        />
        <LayoutSpacer />
        <PostNavigationLink
          currentPost={post}
          showUnpublished={showUnpublished}
          getNewerPost={true}
          title={t("pages.post.navigation.nextTitle")}
        />
      </Navigation>
    )
  );
};

export default PostNavigation;

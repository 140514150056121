import React from "react";
import cx from "classnames";
import styles from "./Navigation.module.css";

const Navigation = ({ children, ...props }) => {
  return (
    <nav
      {...props}
      className={cx("mdl-cell mdl-cell--12-col", styles.container)}
    >
      {children}
    </nav>
  );
};

export default Navigation;

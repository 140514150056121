export const BLOG_POST_ADD = "BLOG_POST_ADD";
export const BLOG_POST_DELETE = "BLOG_POST_DELETE";
export const BLOG_POSTS_FETCH = "BLOG_POSTS_FETCH";
export const BLOG_FETCH_MORE = "BLOG_POSTS_FETCH_MORE";

export const blogPostAdd = post => ({
  type: BLOG_POST_ADD,
  payload: post
});

export const blogPostDelete = id => ({
  type: BLOG_POST_DELETE,
  payload: id
});

export const blogPostsFetch = posts => ({
  type: BLOG_POSTS_FETCH,
  payload: posts
});

export const blogFetchMore = (posts, nextToken) => ({
  type: BLOG_FETCH_MORE,
  payload: {
    posts,
    nextToken
  }
});

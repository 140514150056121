import {
  POST_DATA_ADD,
  POST_FETCH,
  POST_FETCH_SUCCESS,
  // POST_FETCH_FAILURE,
  POST_STAGE_CHANGE,
  POST_EDIT,
  POST_PERSIST,
  POST_PERSIST_SUCCESS,
  POST_PERSIST_FAILURE,
  POST_TOGGLE_IS_PUBLISHED,
  POST_DATA_STAGE_CHANGE
} from "../actions/post-actions";
import galleryReducer from "./gallery-reducer";
import {
  // FETCH_GALLERY,
  FETCH_GALLERY_SUCCESS,
  ADD_SLIDE,
  SLIDE_PERSIST_SUCCESS,
  SLIDE_STASH_CHANGES,
  DELETE_SLIDE,
  ADD_GALLERY_SUCCESS,
  MOVE_SLIDE,
  GALLERY_TOGGLE_IS_PUBLISHED,
  SLIDE_DATA_STAGE_CHANGE,
  SLIDE_DATA_ADD
} from "../actions/gallery-actions";
import postDataReducer from "./postDataReducer";

const initialState = {};

const postReducer = (state = initialState, { type, payload }) => {
  console.log("POST REDUCER: " + type);
  switch (type) {
    case POST_FETCH:
      if (state.id !== payload.id) {
        return state;
      }
      return {
        ...state,
        isLoading: true,
        isEditing: false,
        error: null
      };
    case POST_FETCH_SUCCESS:
      if (state.id !== payload.id) {
        return state;
      }
      return {
        ...state,
        ...payload,
        isLoading: false,
        error: null
      };

    // case POST_FETCH_FAILURE:
    //   if (state.id !== payload.id) {
    //     return state;
    //   }
    //   return {
    //     ...state,
    //     isLoading: false,
    //     error: payload.message
    //   };

    case POST_STAGE_CHANGE:
      if (state.id !== payload.id) {
        return state;
      }
      return {
        ...state,
        ...payload,
        isDirty: true,
        gallery: state.gallery
      };
    case POST_EDIT:
      if (state.id !== payload.id) {
        return state;
      }
      return {
        ...state,
        isEditing: payload.isEditing
      };
    case POST_PERSIST:
      if (state.id !== payload.id) {
        return state;
      }
      return {
        ...state,
        isLoading: true // TODO : do I want different flag?
      };
    case POST_PERSIST_SUCCESS:
      if (state.id !== payload.id) {
        return state;
      }

      return {
        ...state,
        ...payload,
        gallery: state.gallery,
        isEditing: false,
        isLoading: false,
        isDirty: false,
        error: null
      };
    case POST_PERSIST_FAILURE:
      if (state.id !== payload.id) {
        return state;
      }
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case POST_TOGGLE_IS_PUBLISHED:
      if (state.id !== payload.postId) {
        return state;
      }
      return {
        ...state,
        isPublished: payload.isPublished
      };

    case ADD_GALLERY_SUCCESS:
      if (state.id !== payload.albumPostId) {
        return state;
      }
      return {
        ...state,
        gallery: payload
      };

    case ADD_SLIDE:
    case DELETE_SLIDE:
    case MOVE_SLIDE:
    case FETCH_GALLERY_SUCCESS:
    case GALLERY_TOGGLE_IS_PUBLISHED:
    case SLIDE_PERSIST_SUCCESS:
    case SLIDE_STASH_CHANGES:
    case SLIDE_DATA_ADD:
    case SLIDE_DATA_STAGE_CHANGE:
      return {
        ...state,
        gallery: galleryReducer(state.gallery ?? undefined, { type, payload })
      };

    case POST_DATA_ADD:
      console.log(payload)
      if (state.id !== payload.postDataPostId) {
        return state;
      }
      return {
        ...state,
        multilingual: {
          ...state.multilingual,
          items: [payload].concat(state.multilingual.items ?? [])
        }
      }
    case POST_DATA_STAGE_CHANGE:
      return {
        ...state,
        multilingual: {
          items: state.multilingual.items
            .map(multilingualItem => postDataReducer(multilingualItem, { type, payload }))
        }
      };
  
    default:
      return state;
  }
};

export default postReducer;

import {
  BLOG_INIT,
  BLOG_FETCH,
  BLOG_FETCH_SUCCESS,
  BLOG_FETCH_FAILURE
} from "../actions/blog-actions";
import postsReducer from "./posts-reducer";

const blogInitialStage = {
  id: "96a2598c-fe49-4b9f-8524-de6ce4ea8f11",
  isLoading: false,
  error: null
};

export default function blogReducer(
  state = blogInitialStage,
  { type, payload }
) {
  switch (type) {
    case BLOG_INIT:
      return {
        ...blogInitialStage,
        posts: postsReducer(state.posts, { type, payload })
      };
    case BLOG_FETCH:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case BLOG_FETCH_SUCCESS:
      return {
        ...state,
        ...payload,
        isLoading: false,
        error: null
      };
    case BLOG_FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    default:
      return {
        ...state,
        posts: postsReducer(state.posts, { type, payload })
      };
  }
}

import React from "react";
import cx from "classnames";
import styles from "./Card.module.css";

export const CardColumns = {
  4: "mdl-cell--4-col",
  8: "mdl-cell--8-col",
  12: "mdl-cell--12-col"
};

export const CardShadow = {
  0: "",
  4: "mdl-shadow--4dp"
};

const Card = ({
  children,
  className,
  columns = CardColumns[12],
  shadow = CardShadow[4],
  ...props
}) => {
  return (
    <div
      className={cx(
        "mdl-card mdl-cell",
        columns,
        shadow,
        className,
        styles.container
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export default Card;

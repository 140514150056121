import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Auth } from "aws-amplify";
import { blogInit } from "../../actions/blog-actions";
import { userSignOut, userSignIn } from "../../actions/user-actions";
import Button from "../elements/Button";
import Icon from "../elements/Icon";
import ButtonType from "../elements/ButtonType";
import ButtonFeel from "../elements/ButtonFeel";

const SignIn = () => {
  const dispatch = useDispatch();
  const isSignedIn = useSelector(s => s.user.isSignedIn);

  // handles initial user status on app load
  useEffect(() => {
    let isActive = true;
    const checkUser = async () => {
      await Auth.currentAuthenticatedUser()
        .then(data => {
          if (isActive && !isSignedIn) {
            dispatch(userSignIn(data));
            dispatch(blogInit());
          }
        })
        .catch(err => {
          if (isActive && isSignedIn) {
            dispatch(userSignOut());
            dispatch(blogInit());
          }
        });
    };

    checkUser();

    return () => (isActive = false);
  }, [dispatch, isSignedIn]);

  /*
  const signIn = () => {
    dispatch({
      type: "USER_SIGN_IN_PROGRESS",
      inProgress: true
    });
    const userName = "";
    const password = "";
    Auth.signIn(userName, password)
      .then(data =>
        dispatch({
          type: "USER_SIGN_IN",
          user: { data }
        })
      )
      .catch(err => {
        dispatch({
          type: "USER_SIGN_IN_ERROR",
          error: err
        });
        alert(err);
        console.log(err);
      });
  };
  */

  const signOut = () => {
    Auth.signOut()
      .then(data => {
        dispatch(blogInit());
        dispatch(userSignOut());
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <div>
      {/*
	    !isSignedIn && !isSigningIn && (
        <Link
          className="mdl-button mdl-js-button mdl-button--raised mdl-button--accent mdl-js-ripple-effect"
          to="/auth"
          title="Sign in to your account"
          role="button"
        >
          Sign in
        </Link>
      )
      */}

      {isSignedIn && (
        <Button
          type={ButtonType.Icon}
          feel={ButtonFeel.Flat}
          onClick={signOut}
          title="Sign out"
        >
          <Icon icon="power_settings_new" />
        </Button>
      )}
    </div>
  );
};

export default SignIn;

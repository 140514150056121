import React from "react";
import cx from "classnames";
import styles from "./CardMedia.module.css";

const CardMedia = ({ children, className, ...props }) => {
  return (
    <div className={cx("mdl-card__media", styles.media, className)} {...props}>
      {children}
    </div>
  );
};

export default CardMedia;

import { POST_DATA_STAGE_CHANGE } from "../actions/post-actions";

const initialState = {};

const postDataReducer = (state = initialState, { type, payload }) => {
    console.log("POST DATA REDUCER: " + type);
    
    switch (type) {
        case POST_DATA_STAGE_CHANGE:
            if (state.id !== payload.id) {
                return state;
            }
            return {
                ...state,
                ...payload,
                isDirty: true
            };
        default:
            return state;
    }
};
      
export default postDataReducer;
      
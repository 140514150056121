import React from "react";
import { withRouter } from "react-router-dom";
import { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { getNextPostByBlogIdAndDateCreated } from "../../graphql/custom";
import Icon from "../elements/Icon";
import Button from "../elements/Button";
import ButtonType from "../elements/ButtonType";
import styles from "./PostNavigationLink.module.css";
import ButtonFeel from "../elements/ButtonFeel";
import { TrackedLink } from "../elements/Link";
import useMultilingualValue from "../../custom-hooks/useMultilingualValue";

const PostNavigationLink = ({
  currentPost,
  showUnpublished,
  getNewerPost,
  title,
  match
}) => {
  const [newPost, setNewPost] = useState({});
  const [canNavigate, setCanNavigate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isActive = true;

    const getPost = async (
      blogId,
      createdAt,
      getNewerPost,
      showUnpublished,
      token = null
    ) => {
      try {
        const queryParams = {
          blogId: blogId,
          createdAt: getNewerPost ? { gt: createdAt } : { lt: createdAt },
          sortDirection: getNewerPost ? "ASC" : "DESC",
          nextToken: token,
          filter: showUnpublished ? null : { isPublished: { eq: true } }
        };
        const rawData = await API.graphql(
          graphqlOperation(getNextPostByBlogIdAndDateCreated, queryParams)
        );
        const { items, nextToken } = rawData.data.listPostsByBlogId;
        if (!isActive) return;
        if (items.length > 0) {
          setNewPost(items[0]);
          setCanNavigate(true);
        } else if (nextToken) {
          await getPost(
            blogId,
            createdAt,
            getNewerPost,
            showUnpublished,
            nextToken
          );
        } else {
          setCanNavigate(false);
        }
      } catch (error) {
        console.error(error);
      }
    };

    setIsLoading(true);

    // TODO : remove this hack once currentPost doesn't hold old post value until it's updated
    // if (currentPost.id === match.params.id)
    getPost(
      currentPost.postBlogId,
      currentPost.createdAt,
      getNewerPost,
      showUnpublished
    );

    setIsLoading(false);

    return () => (isActive = false);
  }, [
    getNewerPost,
    showUnpublished,
    match.params.id,
    currentPost.id,
    currentPost.postBlogId,
    currentPost.createdAt
  ]);

  const multilingualTitle = useMultilingualValue(newPost, "title");

  return (
    !isLoading &&
    canNavigate && (
      <TrackedLink
        to={location => `${location.pathname.replace(currentPost.id, newPost.id)}`}
        className={styles.link}
        title={title}
        trackingLabel={`post-navigate-${getNewerPost ? "forward" : "back"}-to-${newPost.id}`}
      >
        {getNewerPost && <span>{multilingualTitle}</span>}
        <Button 
          type={ButtonType.Icon} 
          feel={ButtonFeel.Accent} 
          title={title}
        >
          <Icon icon={getNewerPost ? "arrow_forward" : "arrow_back"} />
        </Button>
        {!getNewerPost && <span>{multilingualTitle}</span>}
      </TrackedLink>
    )
  );
};

export default withRouter(PostNavigationLink);

import React from "react";
import PropTypes from "prop-types";
import { Editor } from "@tinymce/tinymce-react";
import { defaultEditorSettings, mceApiKey } from "./PostEditorSettings";

const PostEditor = ({ name, value, settings, onChange }) => {
  const handleEditorChange = (content, _editor) => {
    onChange({ [name]: content });
  };

  return (
    <Editor
      id={name}
      apiKey={mceApiKey}
      initialValue={value}
      value={value}
      init={{ ...defaultEditorSettings, ...settings }}
      onEditorChange={handleEditorChange}
    />
  );
};

PostEditor.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  settings: PropTypes.object,
  onChange: PropTypes.func.isRequired
};

export default PostEditor;

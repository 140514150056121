export const BLOG_INIT = "BLOG_INIT";
export const BLOG_FETCH = "BLOG_FETCH";
export const BLOG_FETCH_SUCCESS = "BLOG_FETCH_SUCCESS";
export const BLOG_FETCH_FAILURE = "BLOG_FETCH_FAILURE";

export const blogInit = () => ({
  type: BLOG_INIT
});

export const blogFetch = () => ({
  type: BLOG_FETCH
});

export const blogFetchSuccess = blog => ({
  type: BLOG_FETCH_SUCCESS,
  payload: blog
});

export const blogFetchFailure = message => ({
  type: BLOG_FETCH_FAILURE,
  payload: message
});

import React from "react";
import styles from "./PostMediaInput.module.css";
import CloudinaryMediaLibrary from "./actions/CloudinaryMediaLibrary";

const PostMediaInput = ({ onChange }) => {
  const libraryOptions = {
    insert_caption: 'Change banner image'
  }

  const onPhotoSelected = asset => {
    onChange({ bannerImg: asset.public_id })
  };

  return (
    <CloudinaryMediaLibrary 
      onSelection={onPhotoSelected} 
      options={libraryOptions} 
      icon="image"
      className={styles.button}
    />
  );
};

export default PostMediaInput;

import React from "react";
import PropTypes from "prop-types";
import styles from "./PostTitle.module.css";
import { TrackedLink } from "../elements/Link";
import { useTranslation } from "react-i18next";

const PostTitle = ({ title, link }) => {
  const { t } = useTranslation();

  return (
    <h3 className={styles.title}>
      {link ? (
        <TrackedLink to={link} title={t("pages.blog.readPost.title")} trackingLabel={`blog-read-post-${link}`}>
          {title}
        </TrackedLink>
      ) : (
        title
      )}
    </h3>
  );
};

PostTitle.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string
};

export default PostTitle;

import React, { useEffect } from "react";
import { graphqlOperation, API } from "aws-amplify";
import PostPreview from "../posts/PostCardPreview";
import BlogShowMoreItems, { postPageLimit } from "./BlogShowMoreItems";
import Error from "../Error";
import { useSelector, useDispatch } from "react-redux";
import useTitle from "../../custom-hooks/useTitle";
import {
  blogFetch,
  blogFetchSuccess,
  blogFetchFailure
} from "../../actions/blog-actions";
import Loading from "../Loading";
import PostActionAdd from "../posts/PostActionAdd";
import {
  getBlogBasicInfo,
  listPostsByBlogIdOrderedDesc
} from "../../graphql/custom";
import { canViewUnpublishedBlogs } from "../auth/auth-component";
import { blogPostsFetch } from "../../actions/posts-actions";
import { useTranslation } from "react-i18next";

const BlogPosts = () => {
  const { t } = useTranslation();
  useTitle(t("pages.blog.title"));

  const dispatch = useDispatch();
  const blog = useSelector(state => state.blog);

  useEffect(() => {
    if (blog.posts.initialized) return;

    let isActive = true;

    dispatch(blogFetch());

    const fetchBlog = async blogId => {
      const queryParams = { id: blogId };
      const rawData = await API.graphql(
        graphqlOperation(getBlogBasicInfo, queryParams)
      );
      return rawData.data.getBlog;
    };

    const fetchPosts = async (blogId, includeUnpublished) => {
      const queryParams = {
        blogId: blogId,
        limit: postPageLimit,
        filter: includeUnpublished ? null : { isPublished: { eq: true } }
      };
      const rawData = await API.graphql(
        graphqlOperation(listPostsByBlogIdOrderedDesc, queryParams)
      );
      return rawData.data.listPostsByBlogId;
    };

    const fetch = async blogId => {
      try {
        const blog = await fetchBlog(blogId);
        if (isActive) dispatch(blogFetchSuccess(blog));
        const includeUnpublished = await canViewUnpublishedBlogs(blog.owner);
        const posts = await fetchPosts(blogId, includeUnpublished);
        if (isActive) dispatch(blogPostsFetch(posts));
      } catch (error) {
        if (isActive) {
          console.error(error);
          dispatch(blogFetchFailure("Error fetching blog."));
        }
      }
    };

    fetch(blog.id);

    return () => (isActive = false);
  }, [blog.id, blog.posts.initialized, dispatch]);

  return (
    <>
      {blog.error && <Error />}
      {blog.isLoading && <Loading />}
      {blog.posts.initialized &&
        blog.posts.items.map(item => <PostPreview key={item.id} post={item} />)}
      {blog.posts.items.length > 0 && <BlogShowMoreItems />}
      <PostActionAdd />
    </>
  );
};

export default BlogPosts;

import React from "react";
import styles from "./AddTag.module.css";
import Button from "../elements/Button";
import Icon from "../elements/Icon";
import ButtonType from "../elements/ButtonType";
import ButtonFeel from "../elements/ButtonFeel";

const AddTag = ({ handleAddTag }) => {
  return (
    <div
      id="add-tag"
      className={`mdl-textfield mdl-js-textfield mdl-textfield--expandable 
        ${styles.container}`}
    >
      {/* using it as label because of for attribute */}
      <Button
        as="label"
        htmlFor="new-tag"
        title="Add tag"
        type={ButtonType.Icon}
        feel={ButtonFeel.Primary}
        className={styles.label}
      >
        <Icon icon="add" />
      </Button>
      <div className="mdl-textfield__expandable-holder">
        <input
          className="mdl-textfield__input"
          type="text"
          id="new-tag"
          onBlur={handleAddTag}
          autoComplete="off"
        />
      </div>
    </div>
  );
};

export default AddTag;

import { API } from "aws-amplify";
import { createAlbumMutation, editPhotoDataMutation } from "../../../graphql/custom";
import {
  createPhoto,
  updatePhoto,
  updatePost,
  deletePhoto,
  createPhotoData,
  deletePhotoData
} from "../../../graphql/mutations";
import {
  persistSlideSuccess,
  persistSlideFailure,
  addGallerySuccess,
  addGalleryFailure,
  slideDataAdd
} from "../../../actions/gallery-actions";

const defaultGallery = {
  // id: uuid,
  isNew: true,
  isPublished: false,
  slides: {
    items: []
  }
}

const saveGallery = (post, dispatch) => {
  const save = async () => {
    post.gallery = post.gallery || defaultGallery

    const gallery = (post.gallery.isNew)
      ? await createGallery(post, dispatch)
      : post.gallery;

    await saveSlides(gallery, dispatch);
  };

  save();
};

const saveSlides = async (gallery, dispatch) => {
  gallery?.slides?.items &&
    gallery.slides.items.map(async (s, _i) => {
      if (s.isDeleted) {
        await deleteSlide(s, dispatch);
        return;
      }

      const slide = s.isNew
        ? await createSlide(gallery.id, s, dispatch)
        : await updateSlide(s, dispatch);
      return slide;
    });
};

const deleteSlide = async (slide, dispatch) => {
  if (slide.isNew) {
    // new slides don't have to be deleted from the DB, just the store
    dispatch(persistSlideSuccess(slide));
    return slide;
  }

  await Promise.all(slide.multilingual.items.map(slideData => 
    deleteSlideDataMutation(slideData).catch(err => console.error(err))
  ));

  return await deleteSlideMutation(slide)
    .then(rawData => {
      const slide = rawData.data.deletePhoto;
      dispatch(persistSlideSuccess(slide));
      return slide;
    })
    .catch(err => {
      console.error(err);
      dispatch(persistSlideFailure("Error deleting slide"));
      return null;
    });
};

const updateSlide = async (slide, dispatch) => {
  const data = slide?.multilingual?.items.filter(i => i.isDirty) 
  data.map(
    multilingualItem => persistSlideDataMutation(multilingualItem)
      .catch(err => {
        console.error(err);
        dispatch(persistSlideFailure("Error saving photo data"));
      })
  )

  if (!slide.isDirty) return slide;
  return await updateSlideMutation(slide)
    .then(rawData => {
      const slide = {
        ...rawData.data.updatePhoto,
        isDirty: false
      };
      dispatch(persistSlideSuccess(slide));
      return slide;
    })
    .catch(err => {
      console.error(err);
      dispatch(persistSlideFailure("Error updating slide"));
      return null;
    });
};

const createSlide = async (galleryId, slide, dispatch) => {
  return createSlideMutation(galleryId, slide)
    .then(async rawData => {
      const s = {
        ...rawData.data.createPhoto,
        multilingual: slide.multilingual,
        tempId: slide.tempId,
        isNew: false
      };
      dispatch(persistSlideSuccess(s));
      
      const [en, cz] = await Promise.all(slide.multilingual?.items?.map(i => 
        createSlideDataMutation(s.id, i.lang, i.caption)
      ));
      dispatch(slideDataAdd(en.data.createPhotoData));
      dispatch(slideDataAdd(cz.data.createPhotoData));  
    
      return s;
    })
    .catch(err => {
      console.error(err);
      dispatch(persistSlideFailure("Error saving new slide"));
      return null;
    });
};

const deleteSlideMutation = async slide => {
  return await API.graphql({
    query: deletePhoto,
    variables: { input: { id: slide.id } },
    authMode: "AMAZON_COGNITO_USER_POOLS"
  });
};

const deleteSlideDataMutation = async slideData => {
  return await API.graphql({
    query: deletePhotoData,
    variables: { input: { id: slideData.id } },
    authMode: "AMAZON_COGNITO_USER_POOLS"
  });
};

const updateSlideMutation = async slide => {
  const inputData = {
    input: {
      id: slide.id,
      caption: slide.caption,
      order: slide.order,
      fullsize: slide.imgUrl,
      thumbnail: slide.imgUrl
    }
  };
  return await API.graphql({
    query: updatePhoto,
    variables: inputData,
    authMode: "AMAZON_COGNITO_USER_POOLS"
  });
};

const createSlideMutation = async (galleryId, slide) => {
  const inputData = {
    input: {
      photoAlbumId: galleryId,
      caption: slide.caption,
      order: slide.order,
      fullsize: slide.fullsize,
      thumbnail: slide.thumbnail
    }
  };
  return await API.graphql({
    query: createPhoto,
    variables: inputData,
    authMode: "AMAZON_COGNITO_USER_POOLS"
  });
};

const createSlideDataMutation = async (slideId, lang, caption) => {
  const inputData = {
    input: {
      photoDataPhotoId: slideId,
      lang: lang,
      caption: caption
    }
  };
  return await API.graphql({
    query: createPhotoData,
    variables: inputData,
    authMode: "AMAZON_COGNITO_USER_POOLS"
  });
};

const createGallery = async (post, dispatch) => {
  const rawGallery = await createGalleryMutation(
    post.id,
    !!post.gallery.isPublished
  )
    .then(data => {
      const gallery = data.data.createAlbum;
      return gallery;
    })
    .then(gallery => {
      linkGalleryToPostMutation(post.id, gallery.id);
      return gallery;
    })
    .then(gallery => {
      gallery.slides = post.gallery.slides;
      dispatch(addGallerySuccess(gallery));
      return gallery;
    })
    .catch(err => {
      console.error(err);
      dispatch(addGalleryFailure(err));
    });

  return {
    ...rawGallery,
    isNew: false,
    slides: post.gallery.slides
  };
};

const createGalleryMutation = async (postId, isPublished) => {
  const inputData = {
    input: {
      albumPostId: postId,
      isPublished
    }
  };
  return await API.graphql({
    query: createAlbumMutation,
    variables: inputData,
    authMode: "AMAZON_COGNITO_USER_POOLS"
  });
};

const linkGalleryToPostMutation = async (postId, galleryId) => {
  const inputData = {
    input: {
      id: postId,
      postAlbumId: galleryId
    }
  };
  return await API.graphql({
    query: updatePost,
    variables: inputData,
    authMode: "AMAZON_COGNITO_USER_POOLS"
  });
};

const persistSlideDataMutation = async slideData => {
  const inputData = {
    input: {
      id: slideData.id,
      caption: slideData.caption,
      thumbnail: slideData.thumbnail,
      fullsize: slideData.fullsize
    }
  };

  return await API.graphql({
    query: editPhotoDataMutation,
    variables: inputData,
    authMode: "AMAZON_COGNITO_USER_POOLS"
  });
};

export default saveGallery;

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { pageSetTitle } from "../actions/page-actions";
import { useTranslation } from "react-i18next";

const useTitle = (title) => {
  const dispatch = useDispatch();  
  const { t } = useTranslation();

  useEffect(() => {
      const pageTitle = `${title ? title + " - " : " "}${t("site.title")}`;
      document.title = pageTitle
      dispatch(pageSetTitle(pageTitle));
  }, [dispatch, title, t]);
};

export default useTitle;

import React, { useEffect } from "react";
import dialogPolyfill from "dialog-polyfill";
import styles from "./Dialog.module.css";
import cx from "classnames";
import ReactDOM from "react-dom";

export const showDialog = id => () => {
  const dialog = document.getElementById(id);
  dialog.showModal();
};

export const closeDialog = id => () => {
  const dialog = document.getElementById(id);
  if (dialog.open) {
    dialog.close();
  }
};

const Dialog = ({ id, children, className, ...props }) => {
  useEffect(() => {
    var dialog = document.getElementById(id);
    dialogPolyfill.registerDialog(dialog);
    dialog.addEventListener('click', function (event) {
      var rect = dialog.getBoundingClientRect();
      var isInDialog=(rect.top <= event.clientY 
        && event.clientY <= rect.top + rect.height 
        && rect.left <= event.clientX 
        && event.clientX <= rect.left + rect.width);
      if (!isInDialog) {
          dialog.close();
      }
    });    
  }, [id]);

  return (
    ReactDOM.createPortal(
    <dialog
      id={id}
      className={cx("mdl-dialog", styles.container, className)}
      {...props}
    >
      {children}
    </dialog>
    , document.getElementById('dialog-placeholder'))
  );
};

export default Dialog;

export const DialogContent = ({ children, ...props }) => {
  return (
    <div className={`mdl-dialog__content ${styles.content}`} {...props}>
      {children}
    </div>
  );
};

export const DialogActions = ({ children, ...props }) => {
  return (
    <div className="mdl-dialog__actions" {...props}>
      {children}
    </div>
  );
};

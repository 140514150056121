export const POST_FETCH = "POST_FETCH";
export const POST_FETCH_SUCCESS = "POST_FETCH_SUCCESS";
export const POST_FETCH_FAILURE = "POST_FETCH_FAILURE";
export const POST_EDIT = "POST_EDIT";
export const POST_STAGE_CHANGE = "POST_STAGE_CHANGE";
export const POST_PERSIST = "POST_PERSIST";
export const POST_PERSIST_SUCCESS = "POST_PERSIST_SUCCESS";
export const POST_PERSIST_FAILURE = "POST_PERSIST_FAILURE";
export const POST_TOGGLE_IS_PUBLISHED = "POST_TOGGLE_IS_PUBLISHED";

export const POST_DATA_ADD = "POST_DATA_ADD";
export const POST_DATA_STAGE_CHANGE = "POST_DATA_STAGE_CHANGE";
export const POST_DATA_FETCH_SUCCESS = "POST_DATA_FETCH_SUCCESS";

export const blogPostDataAdd = postData => ({
  type: POST_DATA_ADD,
  payload: postData
});
export const postDataStageChange = postData => ({
  type: POST_DATA_STAGE_CHANGE,
  payload: postData
});
export const postDataPersistSuccess = postData => ({
  type: POST_DATA_FETCH_SUCCESS,
  payload: postData
});

export const postFetch = () => ({
  type: POST_FETCH
});

export const postFetchSuccess = post => ({
  type: POST_FETCH_SUCCESS,
  payload: post
});

export const postFetchFailure = (postId, message) => ({
  type: POST_FETCH_FAILURE,
  payload: {
    id: postId,
    message
  }
});

export const postEdit = (postId, isEditing) => ({
  type: POST_EDIT,
  payload: {
    id: postId,
    isEditing
  }
});

export const postStageChange = post => ({
  type: POST_STAGE_CHANGE,
  payload: post
});

export const postPersist = post => ({
  type: POST_PERSIST,
  payload: post
});

export const postPersistSuccess = post => ({
  type: POST_PERSIST_SUCCESS,
  payload: post
});

export const postPersistFailure = message => ({
  type: POST_PERSIST_FAILURE,
  payload: message
});

export const postToggleIsPublished = (postId, isPublished) => ({
  type: POST_TOGGLE_IS_PUBLISHED,
  payload: {
    postId,
    isPublished
  }
});

import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import useLanguageFromUrl from "../../custom-hooks/useLanguageFromUrl";
import useMultilingualValue from "../../custom-hooks/useMultilingualValue";

const PostHelmet = ({ post }) => {
  useLanguageFromUrl();
  const { t } = useTranslation();
  const title = useMultilingualValue(post, "title");
  const intro = useMultilingualValue(post, "intro");

  return (
    <Helmet>
      <meta name="description" content={intro} />
      <meta name="keywords" content={post.tags.join(",")} />

      {/* <!-— facebook open graph tags --> */}
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={t("site.title")} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={intro} />
      <meta property="og:image" content={post.bannerImg} />

      {/* <!-— twitter card tags additive with the og: tags --> */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:domain" value={window.location.hostname} />
      <meta name="twitter:title" value={title} />
      <meta name="twitter:description" value={intro} />
      <meta name="twitter:image" content={post.bannerImg} />
      <meta name="twitter:url" value={window.location.href} />
      {/* <meta name="twitter:label1" value="Opens in Theaters" />
      <meta name="twitter:data1" value="December 1, 2015" />
      <meta name="twitter:label2" value="Or on demand" />
      <meta name="twitter:data2" value="at Hulu.com" /> */}
    </Helmet>
  );
};

export default PostHelmet;

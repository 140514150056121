import { API } from "aws-amplify";
import { editPostMutation, editPostDataMutation } from "../../../graphql/custom";
import {
  postPersistFailure,
  postPersistSuccess,
  postPersist
} from "../../../actions/post-actions";
import { showSnackbar } from "../../layout/LayoutSnackbar";

const savePost = (post, dispatch) => {

  const data = post?.multilingual?.items.filter(i => i.isDirty) 
  data.map(
    multilingualItem => persistPostDataMutation(multilingualItem)
      .then(_data => {
        console.log("post data updated")
      })
      .catch(err => {
        showSnackbar({ message: "Failed to update the post data" });
        console.error(err);
        dispatch(postPersistFailure("Error saving post data"));
      })
  )

  if (!post.isDirty) {
    // TODO : this is a fake submit because nothing changed..
    // maybe I just need to dispatch isEditingToggle here?
    dispatch(postPersistSuccess(post));
    return;
  }

  dispatch(postPersist(post));

  persistPostMutation(post)
    .then(_data => {
      showSnackbar({ message: "Post content updated" });
      dispatch(postPersistSuccess(post));
    })
    .catch(err => {
      showSnackbar({ message: "Failed to update the post" });
      console.error(err);
      dispatch(postPersistFailure("Error saving post content"));
    });
};

const persistPostMutation = async post => {
  const inputData = {
    input: {
      id: post.id,
      author: post.author,
      bannerImg: post.bannerImg,
      isPublished: post.isPublished,
      publishedAt: post.publishedAt,
      title: post.title,
      intro: post.intro,
      content: post.content,
      tags: post.tags
    }
  };
  return await API.graphql({
    query: editPostMutation,
    variables: inputData,
    authMode: "AMAZON_COGNITO_USER_POOLS"
  });
};

const persistPostDataMutation = async postData => {
  const inputData = {
    input: {
      id: postData.id,
      isPublished: postData.isPublished,
      title: postData.title,
      intro: postData.intro,
      content: postData.content,
      tags: postData.tags
    }
  };
  return await API.graphql({
    query: editPostDataMutation,
    variables: inputData,
    authMode: "AMAZON_COGNITO_USER_POOLS"
  });
};

export default savePost;

import React from "react";
import { withRouter } from "react-router-dom";

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    // TODO: don't scroll when navigating back?
    if (this.props.location.pathname !== prevProps.location.pathname) {
      try {
        console.log("scroll")
        const elem = document.querySelector(".mdl-layout");
        elem.scrollTo(0, 0);
      } catch {
        console.log("scroll error")
      }
    }
  }

  render() {
    return this.props.children;
  }
}

/*
function ScrollToTop({ history, children }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
      document.querySelector(".mdl-layout__content").scrollTop = 0;
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return <Fragment>{children}</Fragment>;
}
*/

export default withRouter(ScrollToTop);

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const useLanguageFromUrl = () => {
    const { i18n } = useTranslation();
    const { lang } = useParams();

    useEffect(() => {
        if (lang !== i18n.language) {
            i18n.changeLanguage(lang);
        }
    }, [i18n, lang])
}

export default useLanguageFromUrl;
import React from "react";
import useTitle from "../custom-hooks/useTitle.js";
import Card, { CardColumns } from "./card/Card.js";
import CardTitle from "./card/CardTitle.js";
import CardText from "./card/CardText.js";
import CardMedia from "./card/CardMedia.js";
import styles from "./NotFound.module.css";
import { useTranslation } from "react-i18next";

function NotFound(props) {
  const { t } = useTranslation();
  useTitle(t("pages.notFound.title"));

  return (
    <>
      <Card columns={CardColumns[4]}>
        <CardMedia className={styles.media} />
      </Card>
      <Card columns={CardColumns[8]}>
        <CardTitle>{t("pages.notFound.cardTitle")}</CardTitle>
        <CardText><div dangerouslySetInnerHTML={{ __html: t("pages.notFound.cardText") }}/></CardText>
      </Card>
    </>
  );
}

export default NotFound;

import { useTranslation } from "react-i18next";

const useMultilingualValue = (multilingualObject, name = "[MultilingualValue]") => {
    const { i18n } = useTranslation();
    const item = multilingualObject
        ?.multilingual
        ?.items
        .find(i => i.lang === i18n.language);
    
    return item ? item[name] : null;
}
 
export default useMultilingualValue;
import React from "react";
import styles from "./SlideEditPhoto.module.css";
import Button from "../../elements/Button";
import ButtonType from "../../elements/ButtonType";
import ButtonFeel from "../../elements/ButtonFeel";
import Icon from "../../elements/Icon";
import { useDispatch } from "react-redux";
import {
  deleteSlide,
  moveSlide,
  slideDataStageChange
} from "../../../actions/gallery-actions";
import TextArea from "../../elements/TextArea";
import useMultilingualValue from "../../../custom-hooks/useMultilingualValue";
import { useTranslation } from "react-i18next";
import { settings } from "../../../utils/cloudinary"
import { Image, Transformation } from 'cloudinary-react';

const SlideEditPhoto = ({ slide }) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const multilingualStageChange = change => {
    const data = slide?.multilingual?.items.find(i => i.lang === i18n.language)
    const newSlideData = { ...data, ...{ caption: change.target.value, lang: i18n.language } };
    dispatch(slideDataStageChange(newSlideData));
  };

  const removeSlide = () => {
    dispatch(deleteSlide(slide.id ?? slide.tempId, slide.photoAlbumId));
  };

  const moveSlideLeft = () => {
    dispatch(
      moveSlide(slide.id ?? slide.tempId, slide.photoAlbumId, slide.order, -1)
    );
  };

  const moveSlideRight = () => {
    dispatch(
      moveSlide(slide.id ?? slide.tempId, slide.photoAlbumId, slide.order, 1)
    );
  };

  const caption = useMultilingualValue(slide, "caption") ?? slide.caption;

  return (
    <div className={styles.wrapper}>
      <figure className={styles.figure}>
        <picture>
          <Image
            cloudName={settings.cloud_name}
            publicId={slide.fullsize} 
            dpr="auto"
            width="400"
            crop="scale"
            className={styles.picture}
            alt={caption}
          >
            <Transformation quality="auto" fetchFormat="auto" />
          </Image>
        </picture>
        <figcaption className={styles.caption}>
          <TextArea
            value={caption}
            onChange={multilingualStageChange}
            className={styles.captionTextArea}
            autosize={true}
            id={slide.id ?? slide.tempId}
          />
        </figcaption>
      </figure>
      <div className={styles.buttonGroup}>
        <Button
          type={ButtonType.MiniFab}
          feel={ButtonFeel.Accent}
          title="Move left"
          action={moveSlideLeft}
          className={styles.button}
        >
          <Icon icon="keyboard_arrow_left" />
        </Button>
        <Button
          type={ButtonType.MiniFab}
          feel={ButtonFeel.Accent}
          title="Move right"
          action={moveSlideRight}
          className={styles.button}
        >
          <Icon icon="keyboard_arrow_right" />
        </Button>
        <Button
          type={ButtonType.MiniFab}
          feel={ButtonFeel.Accent}
          title="Remove slide"
          action={removeSlide}
          className={styles.button}
        >
          <Icon icon="clear" />
        </Button>
      </div>
    </div>
  );
};

export default SlideEditPhoto;

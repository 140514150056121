import React from "react";
import Tag from "./Tag";
import styles from "./TagList.module.css";

const TagList = ({ tags }) => {
  return (
    <div className={styles.tags}>
      {tags && tags.map(tag => <Tag key={tag} tag={tag} />)}
    </div>
  );
};

export default TagList;

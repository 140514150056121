import React from "react";
import PropTypes from "prop-types";
import styles from "./TextInput.module.css";
import cx from "classnames";

const TextInput = ({ as: As = "input", id, value, placeholder, onChange }) => {
  return (
    <div className={cx("mdl-textfield mdl-js-textfield", styles.textfield)}>
      <input
        className="mdl-textfield__input"
        type="text"
        id={id}
        name={id}
        defaultValue={value}
        placeholder={placeholder}
        onChange={onChange}
        autoComplete="off"
      />
    </div>
  );
};

TextInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default TextInput;

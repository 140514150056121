import React from "react";
import Icon from "./elements/Icon";
import Card from "./card/Card";
import CardTitle from "./card/CardTitle";
import CardText from "./card/CardText";
import { useTranslation } from "react-i18next";

const Error = ({ message }) => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardTitle>
        <Icon icon="bug_report" />
        {t("site.error.cardTitle")}
      </CardTitle>
      <CardText>
        <p>{message ?? t("site.error.defaultMessage")}</p>
      </CardText>
    </Card>
  );
};

export default Error;

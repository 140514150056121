import React, { useState } from "react";
import Button from "../../elements/Button";
import ButtonType from "../../elements/ButtonType";
import ButtonFeel from "../../elements/ButtonFeel";
import Icon from "../../elements/Icon";
import { postEdit, postPersistSuccess } from "../../../actions/post-actions";
import { useDispatch } from "react-redux";
import { fetchGallerySuccess } from "../../../actions/gallery-actions";

const ToggleEditMode = ({ post }) => {
  const dispatch = useDispatch();
  const [postSnapshot, setPostSnapshot] = useState(post);

  const editPost = e => {
    setPostSnapshot(post);
    dispatch(postEdit(post.id, true));
  };

  const cancelEditPost = e => {
    dispatch(postPersistSuccess(postSnapshot));
    if (postSnapshot.gallery) dispatch(fetchGallerySuccess(postSnapshot.gallery));
    dispatch(postEdit(post.id, false));
  };

  return post.isEditing ? (
    <Button
      type={ButtonType.Fab}
      feel={ButtonFeel.Accent}
      title="Cancel edit"
      action={cancelEditPost}
    >
      <Icon icon="clear" />
    </Button>
  ) : (
    <Button
      type={ButtonType.Fab}
      feel={ButtonFeel.Primary}
      title="Edit post"
      action={editPost}
    >
      <Icon icon="edit" />
    </Button>
  );
};

export default ToggleEditMode;

import cloudinary, { createMediaLibrary } from "./cloudinary"

const createCloudinaryLibrary = (editor) => {
  const options = {
    insert_caption: 'Add to text'
  };
  const handler = (data) => {
      data.assets.forEach(asset => { 
          const opt = { 
              width: "auto", 
              dpr: "auto", 
              crop: "scale", 
              fetch_format: "auto",
              quality: "auto",
              responsive: "true",
              responsive_placeholder: "predominant" 
          }
          const img = cloudinary.image(asset.public_id, opt)

          // set default image source to display it properly in tinyMCE editor
          img.src = cloudinary.url(asset.public_id, {
            width: 800,
            dpr: "auto", 
            crop: "scale", 
            fetch_format: "auto",
            quality: "auto",
          })

          editor.insertContent(img.outerHTML)
      })
  }
  const { showMediaLibrary } = createMediaLibrary(options, handler);

  return showMediaLibrary()
}

const setupEditor = function(editor) {

  const showCloudinaryLibrary = createCloudinaryLibrary(editor);

  editor.ui.registry.addButton("cloudinary-image", {
    icon: 'image',
    tooltip: "Insert Image",
    onAction: function() {
      showCloudinaryLibrary();
    }
  })

  editor.on('init', function(e) {
  });

  editor.on('Load', function(e) {
  });
}

export default setupEditor;
import React from "react";
import { Link } from "react-router-dom";
import styles from "./ButtonLink.module.css";
import ButtonType from "./ButtonType";
import ButtonFeel from "./ButtonFeel";
import cx from "classnames";
import withClickTracking from "../hoc/withClickTracking";

export const TrackedButtonLink = ({trackingLabel, ...props}) =>
  withClickTracking(trackingLabel, "Button link click", props.onClick, props)(ButtonLink)

const ButtonLink = ({
  link,
  title = "Link",
  type = ButtonType.Flat,
  feel = ButtonFeel.Flat,
  className,
  children,
  ...props
}) => {
  return (
    <Link
      to={link}
      role="button"
      title={title}
      className={cx(
        "mdl-button mdl-js-button mdl-js-ripple-effect",
        type,
        feel,
        className,
        styles.buttonLink
      )}
      {...props}
    >
      {children}
    </Link>
  );
};

export default ButtonLink;

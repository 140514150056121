/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBlog = /* GraphQL */ `
  mutation CreateBlog($input: CreateBlogInput!) {
    createBlog(input: $input) {
      id
      name
      owner
      createdAt
      updatedAt
      posts {
        items {
          id
          postBlogId
          owner
          author
          isPublished
          createdAt
          publishedAt
          bannerImg
          tags
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateBlog = /* GraphQL */ `
  mutation UpdateBlog($input: UpdateBlogInput!) {
    updateBlog(input: $input) {
      id
      name
      owner
      createdAt
      updatedAt
      posts {
        items {
          id
          postBlogId
          owner
          author
          isPublished
          createdAt
          publishedAt
          bannerImg
          tags
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteBlog = /* GraphQL */ `
  mutation DeleteBlog($input: DeleteBlogInput!) {
    deleteBlog(input: $input) {
      id
      name
      owner
      createdAt
      updatedAt
      posts {
        items {
          id
          postBlogId
          owner
          author
          isPublished
          createdAt
          publishedAt
          bannerImg
          tags
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost($input: CreatePostInput!) {
    createPost(input: $input) {
      id
      postBlogId
      owner
      author
      isPublished
      createdAt
      publishedAt
      bannerImg
      tags
      updatedAt
      blog {
        id
        name
        owner
        createdAt
        updatedAt
        posts {
          nextToken
        }
      }
      multilingual {
        items {
          id
          lang
          postDataPostId
          isPublished
          title
          intro
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      album {
        id
        albumPostId
        isPublished
        owner
        createdAt
        updatedAt
        post {
          id
          postBlogId
          owner
          author
          isPublished
          createdAt
          publishedAt
          bannerImg
          tags
          updatedAt
        }
        photos {
          nextToken
        }
      }
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost($input: UpdatePostInput!) {
    updatePost(input: $input) {
      id
      postBlogId
      owner
      author
      isPublished
      createdAt
      publishedAt
      bannerImg
      tags
      updatedAt
      blog {
        id
        name
        owner
        createdAt
        updatedAt
        posts {
          nextToken
        }
      }
      multilingual {
        items {
          id
          lang
          postDataPostId
          isPublished
          title
          intro
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      album {
        id
        albumPostId
        isPublished
        owner
        createdAt
        updatedAt
        post {
          id
          postBlogId
          owner
          author
          isPublished
          createdAt
          publishedAt
          bannerImg
          tags
          updatedAt
        }
        photos {
          nextToken
        }
      }
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost($input: DeletePostInput!) {
    deletePost(input: $input) {
      id
      postBlogId
      owner
      author
      isPublished
      createdAt
      publishedAt
      bannerImg
      tags
      updatedAt
      blog {
        id
        name
        owner
        createdAt
        updatedAt
        posts {
          nextToken
        }
      }
      multilingual {
        items {
          id
          lang
          postDataPostId
          isPublished
          title
          intro
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      album {
        id
        albumPostId
        isPublished
        owner
        createdAt
        updatedAt
        post {
          id
          postBlogId
          owner
          author
          isPublished
          createdAt
          publishedAt
          bannerImg
          tags
          updatedAt
        }
        photos {
          nextToken
        }
      }
    }
  }
`;
export const createPostData = /* GraphQL */ `
  mutation CreatePostData($input: CreatePostDataInput!) {
    createPostData(input: $input) {
      id
      lang
      postDataPostId
      isPublished
      title
      intro
      content
      createdAt
      updatedAt
      post {
        id
        postBlogId
        owner
        author
        isPublished
        createdAt
        publishedAt
        bannerImg
        tags
        updatedAt
        blog {
          id
          name
          owner
          createdAt
          updatedAt
        }
        multilingual {
          nextToken
        }
        album {
          id
          albumPostId
          isPublished
          owner
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updatePostData = /* GraphQL */ `
  mutation UpdatePostData($input: UpdatePostDataInput!) {
    updatePostData(input: $input) {
      id
      lang
      postDataPostId
      isPublished
      title
      intro
      content
      createdAt
      updatedAt
      post {
        id
        postBlogId
        owner
        author
        isPublished
        createdAt
        publishedAt
        bannerImg
        tags
        updatedAt
        blog {
          id
          name
          owner
          createdAt
          updatedAt
        }
        multilingual {
          nextToken
        }
        album {
          id
          albumPostId
          isPublished
          owner
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deletePostData = /* GraphQL */ `
  mutation DeletePostData($input: DeletePostDataInput!) {
    deletePostData(input: $input) {
      id
      lang
      postDataPostId
      isPublished
      title
      intro
      content
      createdAt
      updatedAt
      post {
        id
        postBlogId
        owner
        author
        isPublished
        createdAt
        publishedAt
        bannerImg
        tags
        updatedAt
        blog {
          id
          name
          owner
          createdAt
          updatedAt
        }
        multilingual {
          nextToken
        }
        album {
          id
          albumPostId
          isPublished
          owner
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createAlbum = /* GraphQL */ `
  mutation CreateAlbum($input: CreateAlbumInput!) {
    createAlbum(input: $input) {
      id
      albumPostId
      isPublished
      owner
      createdAt
      updatedAt
      post {
        id
        postBlogId
        owner
        author
        isPublished
        createdAt
        publishedAt
        bannerImg
        tags
        updatedAt
        blog {
          id
          name
          owner
          createdAt
          updatedAt
        }
        multilingual {
          nextToken
        }
        album {
          id
          albumPostId
          isPublished
          owner
          createdAt
          updatedAt
        }
      }
      photos {
        items {
          id
          photoAlbumId
          createdAt
          caption
          order
          fullsize
          thumbnail
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateAlbum = /* GraphQL */ `
  mutation UpdateAlbum($input: UpdateAlbumInput!) {
    updateAlbum(input: $input) {
      id
      albumPostId
      isPublished
      owner
      createdAt
      updatedAt
      post {
        id
        postBlogId
        owner
        author
        isPublished
        createdAt
        publishedAt
        bannerImg
        tags
        updatedAt
        blog {
          id
          name
          owner
          createdAt
          updatedAt
        }
        multilingual {
          nextToken
        }
        album {
          id
          albumPostId
          isPublished
          owner
          createdAt
          updatedAt
        }
      }
      photos {
        items {
          id
          photoAlbumId
          createdAt
          caption
          order
          fullsize
          thumbnail
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteAlbum = /* GraphQL */ `
  mutation DeleteAlbum($input: DeleteAlbumInput!) {
    deleteAlbum(input: $input) {
      id
      albumPostId
      isPublished
      owner
      createdAt
      updatedAt
      post {
        id
        postBlogId
        owner
        author
        isPublished
        createdAt
        publishedAt
        bannerImg
        tags
        updatedAt
        blog {
          id
          name
          owner
          createdAt
          updatedAt
        }
        multilingual {
          nextToken
        }
        album {
          id
          albumPostId
          isPublished
          owner
          createdAt
          updatedAt
        }
      }
      photos {
        items {
          id
          photoAlbumId
          createdAt
          caption
          order
          fullsize
          thumbnail
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createPhoto = /* GraphQL */ `
  mutation CreatePhoto($input: CreatePhotoInput!) {
    createPhoto(input: $input) {
      id
      photoAlbumId
      createdAt
      caption
      order
      fullsize
      thumbnail
      updatedAt
      album {
        id
        albumPostId
        isPublished
        owner
        createdAt
        updatedAt
        post {
          id
          postBlogId
          owner
          author
          isPublished
          createdAt
          publishedAt
          bannerImg
          tags
          updatedAt
        }
        photos {
          nextToken
        }
      }
      multilingual {
        items {
          id
          lang
          photoDataPhotoId
          caption
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updatePhoto = /* GraphQL */ `
  mutation UpdatePhoto($input: UpdatePhotoInput!) {
    updatePhoto(input: $input) {
      id
      photoAlbumId
      createdAt
      caption
      order
      fullsize
      thumbnail
      updatedAt
      album {
        id
        albumPostId
        isPublished
        owner
        createdAt
        updatedAt
        post {
          id
          postBlogId
          owner
          author
          isPublished
          createdAt
          publishedAt
          bannerImg
          tags
          updatedAt
        }
        photos {
          nextToken
        }
      }
      multilingual {
        items {
          id
          lang
          photoDataPhotoId
          caption
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deletePhoto = /* GraphQL */ `
  mutation DeletePhoto($input: DeletePhotoInput!) {
    deletePhoto(input: $input) {
      id
      photoAlbumId
      createdAt
      caption
      order
      fullsize
      thumbnail
      updatedAt
      album {
        id
        albumPostId
        isPublished
        owner
        createdAt
        updatedAt
        post {
          id
          postBlogId
          owner
          author
          isPublished
          createdAt
          publishedAt
          bannerImg
          tags
          updatedAt
        }
        photos {
          nextToken
        }
      }
      multilingual {
        items {
          id
          lang
          photoDataPhotoId
          caption
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createPhotoData = /* GraphQL */ `
  mutation CreatePhotoData($input: CreatePhotoDataInput!) {
    createPhotoData(input: $input) {
      id
      lang
      photoDataPhotoId
      caption
      createdAt
      updatedAt
      photo {
        id
        photoAlbumId
        createdAt
        caption
        order
        fullsize
        thumbnail
        updatedAt
        album {
          id
          albumPostId
          isPublished
          owner
          createdAt
          updatedAt
        }
        multilingual {
          nextToken
        }
      }
    }
  }
`;
export const updatePhotoData = /* GraphQL */ `
  mutation UpdatePhotoData($input: UpdatePhotoDataInput!) {
    updatePhotoData(input: $input) {
      id
      lang
      photoDataPhotoId
      caption
      createdAt
      updatedAt
      photo {
        id
        photoAlbumId
        createdAt
        caption
        order
        fullsize
        thumbnail
        updatedAt
        album {
          id
          albumPostId
          isPublished
          owner
          createdAt
          updatedAt
        }
        multilingual {
          nextToken
        }
      }
    }
  }
`;
export const deletePhotoData = /* GraphQL */ `
  mutation DeletePhotoData($input: DeletePhotoDataInput!) {
    deletePhotoData(input: $input) {
      id
      lang
      photoDataPhotoId
      caption
      createdAt
      updatedAt
      photo {
        id
        photoAlbumId
        createdAt
        caption
        order
        fullsize
        thumbnail
        updatedAt
        album {
          id
          albumPostId
          isPublished
          owner
          createdAt
          updatedAt
        }
        multilingual {
          nextToken
        }
      }
    }
  }
`;

import React from "react";
import PostComments from "./PostComments";
import Avatar from "../Avatar";
import TagList from "../tags/TagList";
import LayoutSpacer from "../layout/LayoutSpacer";
import PostInfo from "./PostInfo";
import PostMetadata from "./PostMetadata";
import PostMedia from "./PostMedia";
import PostCard from "./PostCard";
import PostTitle from "./PostTitle";
import PostText from "./PostText";
import PostHelmet from "./PostHelmet";
import useMultilingualValue from "../../custom-hooks/useMultilingualValue";

const PostCardView = ({ post }) => {
  return (
    <PostCard>
      <PostHelmet post={post} />
      <PostMedia imageUrl={post.bannerImg}>
        <PostTitle title={useMultilingualValue(post, "title")} />
      </PostMedia>
      <PostMetadata>
        <Avatar />
        <PostInfo author={post.author} publishedAt={post.publishedAt} />
        <LayoutSpacer />
        <TagList tags={post.tags} />
      </PostMetadata>
      <PostText text={useMultilingualValue(post, "intro")} />
      <PostText text={useMultilingualValue(post, "content")} />
      <PostComments post={post} />
    </PostCard>
  );
};

export default PostCardView;

import React from "react";
import Icon from "./elements/Icon";
import styles from "./Loading.module.css";
import { useTranslation } from "react-i18next";

const Loading = () => {
  const { t } = useTranslation();

  return (
    <div id="loading" className={styles.loading}>
      <Icon icon={"explore"} className={styles.rotating} />
      <span>&nbsp;{t("site.loading")}</span>
    </div>
  );
};

export default Loading;

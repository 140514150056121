import React from "react";
import ReactGA from "react-ga";

const trackEvent = (label="unspecified", actionName="click", action) => () => {
    ReactGA.event({
      category: "Interaction",
      action: actionName,
      label: label,
      nonInteraction: false,
    });

    if (action) action();
  }

const withClickTracking = (trackingLabel, trackingActionName, trackingAction, props) => (WrappedComponent) =>
  <WrappedComponent {...props} onClick={trackEvent(trackingLabel, trackingActionName, trackingAction)} />

export default withClickTracking;
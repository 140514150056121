import React from "react";
import PropTypes from "prop-types";
import TextInput from "../elements/TextInput";

const PostTextInput = ({ name, value, placeholder, onChange }) => {
  const handleChange = e => {
    onChange({ [name]: e.target.value });
  };

  return (
    <TextInput
      id={name}
      value={value}
      placeholder={placeholder}
      onChange={handleChange}
    />
  );
};

PostTextInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default PostTextInput;

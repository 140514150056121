import {
  ADD_SLIDE,
  DELETE_SLIDE,
  FETCH_GALLERY,
  FETCH_GALLERY_SUCCESS,
  FETCH_GALLERY_FAILURE,
  SLIDE_PERSIST_SUCCESS,
  SLIDE_STASH_CHANGES,
  MOVE_SLIDE,
  GALLERY_TOGGLE_IS_PUBLISHED,
  SLIDE_DATA_STAGE_CHANGE,
  SLIDE_DATA_ADD
} from "../actions/gallery-actions";
import slideReducer from "./slide-reducer";

const initialState = {
  slides: {
    items: []
  },
  isNew: true,
  isLoading: false,
  error: null
};

const galleryReducer = (state = initialState, { type, payload }) => {
  console.log("GALLERY REDUCER: " + type);
  // if (state?.id !== payload.id) {
  //   console.log(`ID: ${state?.id} <--> payloadId: ${payload.id}`);
  //   return state;
  // }

  switch (type) {
    case FETCH_GALLERY:
      if (state.id !== payload.id) {
        return state;
      }
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case FETCH_GALLERY_SUCCESS:
      if (state.id !== payload.id) {
        return state;
      }
      return {
        ...state,
        ...payload,
        isLoading: false,
        error: null
      };
    case FETCH_GALLERY_FAILURE:
      if (state.id !== payload.id) {
        return state;
      }
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case GALLERY_TOGGLE_IS_PUBLISHED:
      if (state.id !== payload.id) {
        return state;
      }

      return {
        ...state,
        isPublished: payload.isPublished
      };

    case ADD_SLIDE:
      if (state.id !== payload.photoAlbumId) {
        return state;
      }

      return {
        ...state,
        slides: {
          ...state.slides,
          items: [
            payload,
            ...state.slides.items.map(s => ({
              ...s,
              order: s.order + 1,
              isDirty: true
            }))
          ]
        }
      };

    case DELETE_SLIDE:
      if (state.id !== payload.photoAlbumId) {
        return state;
      }
      let decreaseCount = false;
      return {
        ...state,
        slides: {
          ...state.slides,
          items: [
            ...state.slides.items.map(slide => {
              if (decreaseCount) {
                return {
                  ...slide,
                  order: slide.order - 1,
                  isDirty: true
                };
              }
              if (slide.id === payload.id || slide.tempId === payload.id) {
                decreaseCount = true;
                return {
                  ...slide,
                  isDeleted: true
                };
              }

              return slide;
            })
          ]
        }
      };

    case MOVE_SLIDE: {
      if (state.id !== payload.photoAlbumId) {
        return state;
      }
      return {
        ...state,
        slides: {
          ...state.slides,
          items: [
            ...state.slides.items.map(slide => {
              if (
                payload.order + payload.delta < 1 ||
                payload.order + payload.delta > state.slides.items.length
              ) {
                return slide;
              }
              if (slide.order === payload.order) {
                return {
                  ...slide,
                  order: slide.order + payload.delta,
                  isDirty: true
                };
              }
              if (slide.order === payload.order + payload.delta) {
                return {
                  ...slide,
                  order: slide.order - payload.delta,
                  isDirty: true
                };
              }
              return slide;
            })
          ]
        }
      };
    }

    case SLIDE_DATA_ADD:
    case SLIDE_DATA_STAGE_CHANGE:
      return {
        ...state,
        slides: {
          ...state.slides,
          items: state.slides?.items
            ?.map(slide => slideReducer(slide, { type, payload }))
            ?? []
        }
      };

    case SLIDE_STASH_CHANGES:
    case SLIDE_PERSIST_SUCCESS:
      if (state.id !== payload.photoAlbumId) {
        return state;
      }
      return {
        ...state,
        slides: {
          ...state.slides,
          items: state.slides.items
            .map(slide => slideReducer(slide, { type, payload }))
            .filter(slide => slide)
        }
      };
  
    default:
      return state;
  }
};

export default galleryReducer;

import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./TextArea.module.css";
import cx from "classnames";

const TextArea = ({
  id,
  value,
  placeholder,
  onChange,
  className,
  rows = 5,
  autosize = false,
  ...props
}) => {
  const ta = useRef(null);

  useEffect(() => {
    autosize && resize(ta.current);
  }, [autosize]);

  const onKeyUpResize = e => {
    const elem = e.target;
    autosize && resize(elem);
  };

  const resize = elem => {
    elem.style.overflow = "hidden";
    elem.style.height = 0;
    elem.style.height = elem.scrollHeight + "px";
  };

  return (
    <div
      className={cx(
        "mdl-textfield",
        "mdl-js-textfield",
        styles.textfield,
        className
      )}
    >
      <textarea
        ref={ta}
        className={cx("mdl-textfield__input", className)}
        type="text"
        id={id}
        name={id}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onKeyUp={onKeyUpResize}
        autoComplete="off"
        rows={rows}
        {...props}
      ></textarea>
    </div>
  );
};

TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default TextArea;

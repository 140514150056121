import React from "react";
import styles from "./Tag.module.css";
import Icon from "../elements/Icon";

const Tag = ({ tag, handleRemoveTag }) => {
  return (
    <span className={styles.tagChip}>
      <span className="mdl-chip__text">{tag}</span>
      {handleRemoveTag && (
        <span className="mdl-chip__action" onClick={() => handleRemoveTag(tag)}>
          <Icon icon="clear" />
        </span>
      )}
    </span>
  );
};

export default Tag;

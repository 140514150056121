import React from "react";
import { withRouter, Switch, Route } from "react-router-dom";
import styles from "./LayoutHeaderNavigation.module.css";
import { TrackedButtonLink } from "../elements/ButtonLink";
import ButtonType from "../elements/ButtonType";
import Icon from "../elements/Icon";
import ButtonFeel from "../elements/ButtonFeel";
import { TrackedLink } from "../elements/Link";
import { useTranslation } from "react-i18next";

const LayoutHeaderNavigation = ({ match }) => {
  const { t } = useTranslation();

  return (
    <Switch>
      <Route exact path="/:lang/blog">
        <>
          <TrackedButtonLink
            type={ButtonType.Fab}
            feel={ButtonFeel.Default}
            link={`${match.url}/about`}
            title={t("navigation.about")}
            className={styles.buttonLink}
            trackingLabel="blog-about"
          >
            <div className={styles.logo}></div>
          </TrackedButtonLink>
          <span className={styles.title}>{t("site.title")}</span>
        </>
      </Route>
      <Route path="/">
        <>
          <TrackedButtonLink
            type={ButtonType.Fab}
            feel={ButtonFeel.Default}
            link={`${match.url}/blog`}
            title={t("navigation.toBlog")}
            className={styles.buttonLink}
            trackingLabel="blog-back-to"
          >
            <Icon icon="arrow_back" />
          </TrackedButtonLink>
          <span className={styles.title}>
            <TrackedLink to={`${match.url}/blog`} title={t("navigation.toBlog")} className={styles.titleLink} trackingLabel="blog-back-to">
              {t("pages.blog.title")}
            </TrackedLink>
          </span>
        </>
      </Route>
    </Switch>
  );
};

export default withRouter(LayoutHeaderNavigation);

import React from "react";
import PropTypes from "prop-types";
import styles from "./PostMedia.module.css";
import CardMedia from "../card/CardMedia";
import { settings } from "../../utils/cloudinary"
import { Image, Placeholder, Transformation } from 'cloudinary-react';

const PostMedia = ({ children, imageUrl }) => {
  return (
    <CardMedia
      className={styles.media}
    >
      <Image 
        cloudName={settings.cloud_name} 
        publicId={imageUrl}
        dpr="auto"
        responsive
        width="auto"
        crop="scale"
        responsiveUseBreakpoints="true"
        loading="lazy"
      >
        <Transformation quality="auto" fetchFormat="auto" gravity="auto:subject"/>
        <Placeholder type="predominant" />
      </Image>

      {children}
    </CardMedia>
  );
};

PostMedia.propTypes = {
  imageUrl: PropTypes.string.isRequired
};

export default PostMedia;

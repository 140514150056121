import React from "react";
import { withRouter, Route, Switch, Redirect } from "react-router-dom";
import Blog from "../blogs/Blog";
import NotFound from "../NotFound";
import About from "../About";
import cx from "classnames";
import styles from "./LayoutContent.module.css";

const LayoutContent = ({ match }) => {
  return (
    <main className={cx("mdl-layout__content", styles.content)}>
      <div className={cx("mdl-grid", styles.blog)}>
        <Switch>
          <Redirect exact path={`${match.path}`} to={`${match.path}/blog`} />
          <Route path={`${match.path}/blog`} component={Blog} />
          <Route exact path={`${match.path}/about`} component={About} />
          <Route component={NotFound} />
        </Switch>
      </div>
    </main>
  );
};

export default withRouter(LayoutContent);

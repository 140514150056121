import { combineReducers } from "redux";
import pageReducer from "./reducers/page-reducer.js";
import userReducer from "./reducers/user-reducer.js";
import blogReducer from "./reducers/blog-reducer.js";

const rootReducer = combineReducers({
	page: pageReducer,
	user: userReducer,
	blog: blogReducer
});
export default rootReducer


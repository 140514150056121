const postItem = /* GraphQL */ `
  {
    id
    owner
    isPublished
    bannerImg
    author
    publishedAt
    tags
    createdAt
    multilingual {
      items {
          id
          lang
          isPublished
          title
          intro
          content
        }
        nextToken    
    }
    postBlogId
    blog {
      owner
    }
    gallery: album {
      id
      isPublished
    }
  }
`;

const postDataItem = /* GraphQL */ `
  {
    id
    lang
    postDataPostId
    isPublished
    title
    intro
    content
  }
`;

export const getBlogBasicInfo = /* GraphQL */ `
  query GetBlogBasicInfo($id: ID!) {
    getBlog(id: $id) {
      id
      owner
      name
    }
  }
`;

export const listPostsByBlogIdOrderedDesc = /* GraphQL */ `query ListPostsByBlogId(
    $blogId: ID!, 
    $limit: Int!, 
    $filter: ModelPostFilterInput,
    $nextToken: String
  ) {
    listPostsByBlogId(
      postBlogId: $blogId,
      limit: $limit,
      sortDirection: DESC,
      filter: $filter,
      nextToken: $nextToken
    ) {
        items ${postItem}
        nextToken
      }
    }
  `;

export const getPostCustomQuery = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) ${postItem}
  }
`;

export const getNextPostByBlogIdAndDateCreated = /* GraphQL */ `
  query GetNextPostByBlogIdAndDateCreated(
    $blogId: ID!
    $createdAt: ModelStringKeyConditionInput!
    $sortDirection: ModelSortDirection!
    $filter: ModelPostFilterInput
    $nextToken: String
  ) {
    listPostsByBlogId(
      sortDirection: $sortDirection
      postBlogId: $blogId
      createdAt: $createdAt
      limit: 2
      nextToken: $nextToken
      filter: $filter
    ) {
      items {
        id
        multilingual {
          items {
            lang
            title
          }
          nextToken    
        }
      }
      nextToken
    }
  }
`;

export const editPostMutation = /* GraphQL */ `
  mutation UpdatePost($input: UpdatePostInput!) {
    updatePost(input: $input) ${postItem}
  }`;

export const postIsPublishedMutation = /* GraphQL */ `
  mutation SetIsPublished($postId: ID!, $isPublished: Boolean!) {
    updatePost(input: { id: $postId, isPublished: $isPublished }) {
      id
      isPublished
    }
  }
`;

export const editPostDataMutation = /* GraphQL */ `
  mutation UpdatePostData($input: UpdatePostDataInput!) {
    updatePostData(input: $input) ${postDataItem}
  }`;

export const createAlbumMutation = /* GraphQL */ `
  mutation CreateAlbum($input: CreateAlbumInput!) {
    createAlbum(input: $input) {
      id
      albumPostId
      owner
    }
  }
`;

export const editPhotoDataMutation = /* GraphQL */ `
  mutation UpdatePhotoData($input: UpdatePhotoDataInput!) {
    updatePhotoData(input: $input) {
      id
      lang
      photoDataPhotoId
      caption
      fullsize
      thumbnail
    }
  }`;

export const galleryIsPublishedMutation = /* GraphQL */ `
  mutation SetGalleryIsPublished($galleryId: ID!, $isPublished: Boolean!) {
    updateAlbum(input: { id: $galleryId, isPublished: $isPublished }) {
      id
      isPublished
    }
  }
`;

export const getAlbumCustomQuery = /* GraphQL */ `
  query GetAlbum($id: ID!) {
    getAlbum(id: $id) {
      id
      albumPostId
      owner
      createdAt
      isPublished
      slides: photos(limit: 100) {
        items {
          id
          photoAlbumId
          createdAt
          caption
          multilingual {
            items {
              id
              lang
              caption
            }
            nextToken
          }
          order
          fullsize
          thumbnail
        }
        nextToken
      }
    }
  }
`;

import React from "react";
import styles from "./Clickable.module.css";
import withClickTracking from "../hoc/withClickTracking";

export const TrackedClickable = ({trackingLabel, ...props}) =>
  withClickTracking(trackingLabel, "Clickable click", props.onClick, props)(Clickable)

const Clickable = ({ 
  as: As = "div", 
  children, 
  onClick, 
  ...props 
}) => {
  return (
    <As onClick={onClick} className={styles.wrapper} {...props}>
      {children}
    </As>
  );
};

export default Clickable;

import buttonStyles from "./Button.module.css";

const ButtonFeel = {
  Default: buttonStyles.button,
  Flat: buttonStyles.flatButton,
  Primary: buttonStyles.primaryButton,
  Accent: buttonStyles.accentButton
};

export default ButtonFeel;

import React, { useState } from "react";
import { TrackedButton } from "./elements/Button";
import Cookies from "universal-cookie";
import styles from "./CookiePolicy.module.css";
import ButtonType from "./elements/ButtonType";
import ButtonFeel from "./elements/ButtonFeel";
import { useTranslation } from "react-i18next";

const CookiePolicy = () => {
  const cookiePolicyKey = "cookie-policy-accepted";
  const cookies = new Cookies();
  const cookiesOptions = { path: "/" };
  const { t } = useTranslation();
  const [isPolicyAlreadySet, setIsPolicyAlreadySet] = useState(
    cookies.get(cookiePolicyKey, cookiesOptions)
  );

  const saveCookie = () => {
    cookies.set(cookiePolicyKey, true, cookiesOptions);
    setIsPolicyAlreadySet(true);
  };

  return (
    !isPolicyAlreadySet && (
      <div className={styles.container}>
        <div className={styles.text}>
          <span role="img" aria-label="Cookie">
            🍪
          </span>
          &nbsp;{t("site.cookies.text")}
        </div>
        <div className={styles.spacer} />
        <div className={styles.action}>
          <TrackedButton
            trackingLabel="accept-cookies"
            type={ButtonType.Raised}
            feel={ButtonFeel.Default}
            title={t("site.cookies.buttonTitle")}
            action={saveCookie}
          >
            {t("site.cookies.buttonText")}
          </TrackedButton>
        </div>
      </div>
    )
  );
};

export default CookiePolicy;

import React from "react";
import Tag from "./Tag";
import styles from "./TagList.module.css";
import AddTag from "./AddTag";

const TagList = ({ tags, onChange }) => {
  const handleAddTag = e => {
    if (e.target.value) {
      onChange({ tags: [e.target.value, ...tags] });
      e.target.value = "";
      document.getElementById("add-tag").classList.remove("is-dirty");
    }
  };

  const handleRemoveTag = tag => {
    const newTags = tags.filter(t => t !== tag);
    onChange({ tags: newTags });
  };

  return (
    <div className={styles.tags}>
      <AddTag handleAddTag={handleAddTag} />
      {tags &&
        tags.map(tag => (
          <Tag key={tag} tag={tag} handleRemoveTag={handleRemoveTag} />
        ))}
    </div>
  );
};

export default TagList;

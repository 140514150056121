import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { API, graphqlOperation } from "aws-amplify";
import PostNavigation from "./PostNavigation";
import useTitle from "../../custom-hooks/useTitle";
import PostCardEdit from "./PostCardEdit";
// import ActionMenu from "../action-menu-component";
import Error from "../Error";
import Loading from "../Loading";
import { isAuthorizedAsOwner } from "../auth/auth-component";
import {
  postFetch,
  postFetchSuccess,
  postFetchFailure
} from "../../actions/post-actions";
import PostCardView from "./PostCardView";
import PostGallery from "./PostGallery";
import PostActions from "./PostActions";
import { getPostCustomQuery } from "../../graphql/custom";
// import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useMultilingualValue from "../../custom-hooks/useMultilingualValue";

const Post = ({ match }) => {
  const dispatch = useDispatch();
  const [isAuthorizedToEditPost, setIsAuthorizedToEditPost] = useState(false);
  const post = useSelector(
    s => s.blog.posts.items.find(p => p.id === match.params.id) ?? {}
  );
  const [error, setError] = useState("");
  const { t } = useTranslation();
  const title = useMultilingualValue(post, "title")

  useTitle(title);
  useEffect(() => {
    let isActive = true;

    const fetchPost = async postId => {
      try {
        if (!isActive) return;
        dispatch(postFetch());
        const queryParams = { id: postId };
        const data = await API.graphql(
          graphqlOperation(getPostCustomQuery, queryParams)
        );
        let post = data.data.getPost;
        if (post) {
          dispatch(postFetchSuccess(post));
        } else {
          setError(t("errors.postDoesNotExist"));
        }
        return post;
      } catch (e) {
        dispatch(postFetchFailure(postId, t("errors.postDoesNotExist")));
        console.log("Error fetching post data:", e);
        return null;
      }
    };

    if (post.id !== match.params.id) {
      fetchPost(match.params.id);
    }

    return () => (isActive = false);
  }, [dispatch, post.id, match.params.id, t]);

  useEffect(() => {
    const isAuthorized = async () => {
      if (post.id) {
        const isPostOwner = await isAuthorizedAsOwner(post.owner);
        setIsAuthorizedToEditPost(isPostOwner);
      }
    };

    isAuthorized();
  }, [dispatch, post.id, post.owner]);

  if (error) return <Error message={error} />;
  if (post.isLoading) return <Loading />;
  if (!post.id) return null;

  // TODO : this doesn't work well
  // if (!isAuthorizedToEditPost && !post.isPublished) return <Redirect to="/" />;

  return (
    <>
      {isAuthorizedToEditPost && <PostActions post={post} />}
      {isAuthorizedToEditPost && post.isEditing && <PostCardEdit post={post} />}
      {!post.isEditing && <PostCardView post={post} />}
      {/* { isSignedIn && <ActionMenu actions={actions}/> } */}
      <PostGallery post={post} />
      {!post.isEditing && <PostNavigation post={post} />}
    </>
  );
};

export default Post;

import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import useLanguageFromUrl from "../../custom-hooks/useLanguageFromUrl";
import { useSelector } from "react-redux";

const BlogHelmet = ({ blog }) => {
  useLanguageFromUrl();
  const { t, i18n } = useTranslation()
  const name = t("site.title");
  const title = useSelector(x => x.page.title);
  const description = t("site.description");
  const imgUrl = "https://blog-verca-dev.s3.eu-central-1.amazonaws.com/public/blog/Main/desierto.jpg";

  return (
    <Helmet>
      <html lang={i18n.language}/>
      <title>{title}</title>
      <link rel="canonical" href={`https://dvelamysbatohem.cz/${i18n.language}/blog`} />
      <meta name="description" content={description} />

      {/* <!-— facebook open graph tags --> */}
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={name} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:title" content={name} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imgUrl} />

      {/* <!-— twitter card tags additive with the og: tags --> */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:domain" value={window.location.hostname} />
      <meta name="twitter:title" value={name} />
      <meta name="twitter:description" value={description} />
      <meta name="twitter:image" content={imgUrl} />
      <meta name="twitter:url" value={window.location.href} />
    </Helmet>
  );
};

export default BlogHelmet;

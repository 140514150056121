import React from "react";
import cx from "classnames";
import styles from "./CardTitle.module.css";

const CardTitle = ({ as: As = "h2", children, className, ...props }) => {
  return (
    <div className={cx("mdl-card__title", styles.title)}>
      <As className="mdl-card__title-text">{children}</As>
    </div>
  );
};

export default CardTitle;

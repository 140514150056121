import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import { API } from "aws-amplify";
import { createPost, createPostData } from "../../graphql/mutations";
import todoImage from "../../images/todo.png";
import { isAuthorizedAsOwner } from "../auth/auth-component";
import "../../styles/action-menu.css";
import TextInput from "../elements/TextInput";
import Button from "../elements/Button";
import ButtonType from "../elements/ButtonType";
import ButtonFeel from "../elements/ButtonFeel";
import Icon from "../elements/Icon";
import Dialog, {
  DialogContent,
  DialogActions,
  closeDialog,
  showDialog
} from "../elements/Dialog";
import { blogPostAdd } from "../../actions/posts-actions";
import { LOCALE_CZ, LOCALE_EN } from "../../i18n";
import { blogPostDataAdd } from "../../actions/post-actions";

const PostActionAdd = props => {
  const dispatch = useDispatch();
  const [isAuthorizedToAddNewPost, setIsAuthorizedToAddNewPost] = useState(
    false
  );
  const [postId, setPostId] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState(null);
  const [postWasCreated, setPostWasCreated] = useState(null);
  const blog = useSelector(state => state.blog);
  const id = "add-post-dialog";

  useEffect(() => {
    let active = true;
    const checkIfAuthorized = async () => {
      const is = await isAuthorizedAsOwner(blog.owner);
      active && setIsAuthorizedToAddNewPost(is);
    };
    checkIfAuthorized();
    return () => (active = false);
  }, [blog.owner]);

  const handleIdChange = e => {
    setPostId(e.target.value);
    setError(null);
  };

  const addPost = async () => {
    setIsCreating(true);
    try {
      const newPost = {
        input: {
          id: postId,
          postBlogId: blog.id,
          author: "Author",
          isPublished: false,
          publishedAt: "Date",
          bannerImg: todoImage,
          tags: ["Tag me!"]
        }
      };
      const response = await API.graphql({
        query: createPost,
        variables: newPost,
        authMode: "AMAZON_COGNITO_USER_POOLS"
      });
      dispatch(blogPostAdd(response.data.createPost));

      const newPostDataEn = {
        input: {
          postDataPostId: postId,
          lang: LOCALE_EN,
          isPublished: false,
          title: "EN: " + postId.split("-").join(" "),
          intro: "Intro",
          content: "Content"
        }
      };
      const dataResponseEn = await API.graphql({
        query: createPostData,
        variables: newPostDataEn,
        authMode: "AMAZON_COGNITO_USER_POOLS"
      });
      dispatch(blogPostDataAdd(dataResponseEn.data.createPostData));

      const newPostDataCz = {
        input: {
          postDataPostId: postId,
          lang: LOCALE_CZ,
          isPublished: false,
          title: "CZ: " + postId.split("-").join(" "),
          intro: "Uvod",
          content: "Obsah"
        }
      };
      const dataResponseCz = await API.graphql({
        query: createPostData,
        variables: newPostDataCz,
        authMode: "AMAZON_COGNITO_USER_POOLS"
      });
      dispatch(blogPostDataAdd(dataResponseCz.data.createPostData));

      setIsCreating(false);
      setPostWasCreated(true);
    } catch (error) {
      console.error(error);
      setIsCreating(false);
      setError("Failed to create post, try different id before calling Pedro");
    }
  };

  return (
    isAuthorizedToAddNewPost && (
      // TODO : extract action menu component and fix styles as well
      <div className="action-menu">
        {postWasCreated && <Redirect to={`${props.match.url}/${postId}`} />}

        <Button
          type={ButtonType.Fab}
          feel={ButtonFeel.Primary}
          action={showDialog(id)}
          inProgress={isCreating}
          title="Add new post"
        >
          <Icon icon="add" />
        </Button>

        <Dialog id={id}>
          <DialogContent>
            <p>
              Your new post needs a nice ID that will become part of the URL and
              it is not easy to change it afterwards so think twice! It has to
              be unique across all your posts and
              "ideally-in-a-format-like-this"
            </p>
            <TextInput
              id="post-id"
              placeholder="post-id-goes-here"
              onChange={handleIdChange}
            />
            {/* TODO: Extract into inline error component */}
            {error && <p style={{ color: "red" }}>{error}</p>}
          </DialogContent>
          <DialogActions>
            <Button
              type={ButtonType.Flat}
              feel={ButtonFeel.Flat}
              title="Cancel action"
              onClick={closeDialog(id)}
            >
              Cancel
            </Button>
            <Button
              type={ButtonType.Raised}
              feel={ButtonFeel.Primary}
              title="Create new post"
              onClick={addPost}
              disabled={!postId}
            >
              Create new post
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  );
};

export default withRouter(PostActionAdd);

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import usePageVisibility from "./usePageVisibility"

// TODO: use env variables
const TRACKING_CODE = "UA-170212921-1";
const HEARTBEAT_INTERVAL_MS = 15000;

const heartbeat = (path, isVisible) => () => {
  if (!isVisible) return;
  ReactGA.event({
    category: "Background",
    action: "Heartbeat",
    label: `Heartbeat ${path}`,
    nonInteraction: true,
  });
}

const usePageTracking = () => {
  const location = useLocation();
  const isVisible = usePageVisibility();

  //TODO: init should be standalone
  useEffect(() => {
    ReactGA.initialize(TRACKING_CODE, {
      gaOptions: {
        sampleRate: 100,
      }
    });
  }, []);

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    const interval = setInterval(heartbeat(location.pathname, isVisible), HEARTBEAT_INTERVAL_MS);
    return () => clearInterval(interval);
  }, [location, isVisible]);
};

export default usePageTracking;
import React, { useEffect } from "react";
import SignIn from "../auth/sign-in-component";
import LayoutHeaderNavigation from "./LayoutHeaderNavigation";
import LayoutSpacer from "./LayoutSpacer";
import throttle from "../../utils/throttle";
import styles from "./LayoutHeader.module.css";
import scrollHelper from "../../utils/scroll-helper";
import LanguageSelector from "../LanguageSelector";

const LayoutHeader = () => {
  useEffect(() => {
    // layout is the element that scrolls, not the body
    const scrollingElement = document.querySelector("#layout");
    const scrollUp = styles.scrollUp;
    const scrollDown = styles.scrollDown;
    const throttleTime = 400;

    const stickyHeaderOnScrollUp = () =>
      scrollHelper(scrollingElement, scrollUp, scrollDown);
    const throttledStickyHeaderOnScrollUp = () => {
      throttle(stickyHeaderOnScrollUp, throttleTime);
    };

    scrollingElement.addEventListener(
      "scroll",
      throttledStickyHeaderOnScrollUp
    );
  }, []);

  return (
    <header className={styles.header}>
      <div className={styles.headerRow}>
        <LayoutHeaderNavigation />
        <LayoutSpacer />
        <LanguageSelector />
        <SignIn />
      </div>
    </header>
  );
};

export default LayoutHeader;

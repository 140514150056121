import React, { useState } from "react";
import Disqus from "disqus-react";
import { TrackedButton } from "../elements/Button";
import styles from "./PostComments.module.css";
import ButtonType from "../elements/ButtonType";
import ButtonFeel from "../elements/ButtonFeel";
import Icon from "../elements/Icon";
import { useTranslation } from "react-i18next";

const PostComments = ({ post }) => {
  const disqusShortname = "dvelamysbatohem";
  const disqusConfig = {
    url: window.location.href, // this.props.article.url,
    identifier: post.id, // this.props.article.id,
    title: post.title // this.props.article.title,
  };
  const [showComments, setShowComments] = useState(false);
  const { t } = useTranslation();
  const handleOnClick = () => {
    setShowComments(!showComments);
  };
  // const { i18n } = useTranslation();

  return (
    <div className={styles.container}>
      <TrackedButton
        trackingLabel={showComments ? "comments-hide" : "comments-show"}
        type={ButtonType.Fab}
        feel={ButtonFeel.Primary}
        action={handleOnClick}
        className={styles.button}
        title={showComments ? t("pages.post.comments.hideTitle") : t("pages.post.comments.showTitle")}
      >
        <Icon icon={showComments ? "keyboard_arrow_up" : "insert_comment"} />
      </TrackedButton>

      {
        <div className={showComments ? styles.comments : styles.commentsHidden}>
          {showComments && (
            <Disqus.DiscussionEmbed
              shortname={disqusShortname}
              config={disqusConfig}
              // language="zh_TW"
              // language={i18n.language}
            />
          )}
        </div>
      }
    </div>
  );
};

export default PostComments;

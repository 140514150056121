import React from "react";
import Post from "../posts/post-component";
import BlogPosts from "./BlogPosts";
import { Route, Switch } from "react-router-dom";
import NotFound from "../NotFound";

function Blog(props) {
  return (
    <>
      <Switch>
        <Route exact path={`${props.match.path}/:id`} component={Post} />
        <Route exact path={`${props.match.path}`} component={BlogPosts} />
        <Route component={NotFound} />
      </Switch>
    </>
  );
}

export default Blog;

import React from "react";
import styles from "./CardText.module.css";
import cx from "classnames";

const CardText = ({ children }) => {
  return (
    <div className={cx("mdl-card__supporting-text", styles.container)}>
      {children}
    </div>
  );
};

export default CardText;

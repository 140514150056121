import React from "react";
import Button from "../../elements/Button";
import ButtonType from "../../elements/ButtonType";
import ButtonFeel from "../../elements/ButtonFeel";
import { useDispatch } from "react-redux";
import Icon from "../../elements/Icon";
import { galleryIsPublishedMutation } from "../../../graphql/custom";
import { API } from "aws-amplify";
import { galleryToggleIsPublished } from "../../../actions/gallery-actions";

const PublishToggle = ({ gallery }) => {
  const dispatch = useDispatch();

  const togglePublish = () => {
    try {
      const updatedIsPublished = !gallery.isPublished;
      if (!gallery.isNew) {
        API.graphql({
          query: galleryIsPublishedMutation,
          variables: { galleryId: gallery.id, isPublished: updatedIsPublished },
          authMode: "AMAZON_COGNITO_USER_POOLS"
        });
      }
      dispatch(galleryToggleIsPublished(gallery.id, updatedIsPublished));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Button
      type={ButtonType.Fab}
      feel={ButtonFeel.Accent}
      title={gallery.isPublished ? "Unpublish" : "Publish"}
      action={togglePublish}
    >
      <Icon icon={gallery.isPublished ? "cloud" : "cloud_off"} />
    </Button>
  );
};

export default PublishToggle;

import React, { useEffect, useState } from "react";
import Card, { CardShadow } from "../card/Card";
import styles from "./PostGallery.module.css";
import uuid from "react-uuid";
import Slider from "react-slick";
import SlideAddPhoto from "./gallery/SlideAddPhoto";
import SlideViewPhoto from "./gallery/SlideViewPhoto";
import SlideEditPhoto from "./gallery/SlideEditPhoto";
import {
  fetchGallery as fetchGalleryAction,
  fetchGallerySuccess,
  fetchGalleryFailure
} from "../../actions/gallery-actions";
import { getSettings, getFullscreenSettings } from "./gallery/sliderSettings";
import { API, graphqlOperation } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { getAlbumCustomQuery } from "../../graphql/custom";
import Dialog, {
  DialogContent,
  showDialog,
  closeDialog
} from "../elements/Dialog";
import { TrackedButton } from "../elements/Button";
import Icon from "../elements/Icon";
import ButtonType from "../elements/ButtonType";
import ButtonFeel from "../elements/ButtonFeel";
import SlideViewFullscreenPhoto from "./gallery/SlideViewFullscreenPhoto";
import { TrackedClickable } from "../elements/Clickable";
import { useTranslation } from "react-i18next";

const PostGallery = ({ post, ...props }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const gallery = useSelector(
    state => state.blog.posts.items.find(p => p.id === post.id)?.gallery
  ) ?? {
    // id: uuid,
    isPublished: false,
    slides: {
      items: []
    }
  };

  useEffect(() => {
    let isActive = true;

    const fetchGallery = async galleryId => {
      try {
        if (!isActive) return;
        dispatch(fetchGalleryAction());
        const queryParams = { id: galleryId };
        const rawData = await API.graphql(
          graphqlOperation(getAlbumCustomQuery, queryParams)
        );
        const gallery = rawData.data.getAlbum;
        dispatch(fetchGallerySuccess(gallery));
        return gallery;
      } catch (e) {
        dispatch(fetchGalleryFailure(e));
        console.log("Error fetching gallery data:", e);
        return null;
      }
    };

    if (gallery.id) {
      fetchGallery(gallery.id);
    }

    return () => (isActive = false);
  }, [dispatch, gallery.id]);

  // TODO : base this flag on user being signed in or not
  const displaySlideToAddPhotos = post.isEditing ?? false;
  const slides =
    gallery.slides?.items
      ?.filter(s => !s.isDeleted)
      ?.sort((a, b) => a.order - b.order) ?? [];
  const numberOfSlides = slides.length + displaySlideToAddPhotos;
  const settings = getSettings(numberOfSlides, styles.slider);
  const fullscreenSettings = getFullscreenSettings(styles.slider);
  const isPublished = gallery.isPublished ?? false;
  const renderGallery =
    displaySlideToAddPhotos || (isPublished && slides.length > 0);

  const dialogId = "gallery_full_screen";
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [displayFullscreen, setDisplayFullscreen] = useState(false);
  const onFullscreenRequestOpen = index => {
    return () => {
      setSelectedSlide(index);
      setDisplayFullscreen(true);
      showDialog(dialogId)();
    };
  };

  const onFullscreenRequestClose = () => {
    closeDialog(dialogId)();
    setDisplayFullscreen(false);
  };

  return (
    renderGallery && (
      <Card className={styles.container} shadow={CardShadow[0]}>
        <Slider {...settings}>
          {displaySlideToAddPhotos && (
            <SlideAddPhoto gallery={gallery} key={uuid()} />
          )}
          {slides.map((slide, index) =>
            post.isEditing ? (
              <SlideEditPhoto slide={slide} key={slide.id ?? slide.tempId} />
            ) : (
              <TrackedClickable
                trackingLabel="gallery-fullscreen-open"
                onClick={onFullscreenRequestOpen(index)}
                key={slide.id ?? slide.tempId}
              >
                <SlideViewPhoto slide={slide} />
              </TrackedClickable>
            )
          )}
        </Slider>

        <Dialog
          id={dialogId}
          className={styles.dialog}
          onClose={onFullscreenRequestClose}
        >
          <DialogContent className={styles.dialogContent}>
            {displayFullscreen && (
              <Slider {...fullscreenSettings} initialSlide={selectedSlide}>
                {slides.map(slide => (
                  <SlideViewFullscreenPhoto
                    slide={slide}
                    key={slide.id ?? slide.tempId}
                  />
                ))}
              </Slider>
            )}
            <TrackedButton
              trackingLabel="gallery-fullscreen-close"
              type={ButtonType.MiniFab}
              feel={ButtonFeel.Accent}
              title={t("pages.post.gallery.closeTitle")}
              action={onFullscreenRequestClose}
              className={styles.dialogCloseButton}
            >
              <Icon icon="clear" />
            </TrackedButton>
          </DialogContent>
        </Dialog>
      </Card>
    )
  );
};

export default PostGallery;

import React from "react";
import styles from "./PostActionReadMore.module.css";
import { TrackedButtonLink } from "../elements/ButtonLink";
import ButtonType from "../elements/ButtonType";
import ButtonFeel from "../elements/ButtonFeel";
import { useTranslation } from "react-i18next";
import Icon from "../elements/Icon"

const PostActionReadMore = ({ text, link }) => {
  const { t } = useTranslation();

  return (
    <TrackedButtonLink
      link={link}
      title={t("pages.blog.readPost.title")}
      type={ButtonType.Raised}
      feel={ButtonFeel.Accent}
      className={styles.readMore}
      trackingLabel={`blog-read-post-${link}`}
    >
      {t("pages.blog.readPost.icon") && <Icon icon={t("pages.blog.readPost.icon")} />}
      {t("pages.blog.readPost.text")}
    </TrackedButtonLink>
  );
};

export default PostActionReadMore;

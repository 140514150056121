export const PAGE_SET_TITLE = "PAGE_SET_TITLE";
export const PAGE_SET_BREADCRUMBS = "PAGE_SET_BREADCRUMBS";

export const pageSetTitle = (title) => ({
    type: PAGE_SET_TITLE,
    payload: title,
});

export const pageSetBreadcrumbs = (breadcrumbs) => ({
    type: PAGE_SET_BREADCRUMBS,
    payload: breadcrumbs,
});

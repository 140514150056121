/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_content_delivery_bucket": "blog-hosting-bucket-dev",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "http://blog-hosting-bucket-dev.s3-website.eu-central-1.amazonaws.com",
    "aws_cognito_identity_pool_id": "eu-central-1:6e94ae72-3c19-462f-b4de-2949ebd0d439",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_qMJjHuckF",
    "aws_user_pools_web_client_id": "64hpa796sgcikeggq1l7pjaslo",
    "oauth": {},
    "aws_user_files_s3_bucket": "blog-verca-dev",
    "aws_user_files_s3_bucket_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://kjjxgynaqrd37m3lgbpcvg7t3i.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AWS_IAM"
};


export default awsmobile;

import { USER_SIGN_IN, USER_SIGN_OUT } from "../actions/user-actions";

const initialState = {
	user: null,
	isSignedIn: false
};

export default function userReducer(state = initialState, action) {
	switch (action.type) {
		case USER_SIGN_IN:
			return {
				user: action.payload,
				isSignedIn: true
			};
		case USER_SIGN_OUT:
			return {
				user: null,
				isSignedIn: false
			};
		default:
			return state;
	}
};

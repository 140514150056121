import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./Button.module.css";
import ButtonType from "./ButtonType";
import ButtonFeel from "./ButtonFeel";
import cx from "classnames";
import withClickTracking from "../hoc/withClickTracking";

export const TrackedButton = ({trackingLabel, ...props}) =>
  withClickTracking(trackingLabel, "Button click", props.action, props)(Button)

const Button = ({
  as: As = "button",
  children,
  type = ButtonType.Flat,
  feel = ButtonFeel.Default,
  action,
  title = "Button",
  inProgress = false,
  className = "",
  ...props
}) => {
  const btnElement = useRef(null);

  useEffect(() => {
    btnElement &&
      btnElement.current &&
      window.componentHandler &&
      window.componentHandler.upgradeElement(
        btnElement.current,
        "MaterialButton"
      );
  }, []);

  return (
    <As
      ref={btnElement}
      title={title}
      className={cx(
        "mdl-button mdl-js-button mdl-js-ripple-effect",
        className,
        type,
        feel,
        styles.button
      )}
      // ${inProgress ? "rotating" : null}
      onClick={action}
      disabled={inProgress}
      {...props}
    >
      {children}
      {/* {inProgress ? (
        <i className="material-icons" role="presentation">
          explore
        </i>
      ) : (
        <i className="material-icons" role="presentation">
          {icon}
        </i>
      )} */}
    </As>
  );
};

Button.propTypes = {
  title: PropTypes.string,
  action: PropTypes.func,
  inProgress: PropTypes.bool,
  trackingLabel: PropTypes.string
};

export default Button;

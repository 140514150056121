import React, { useEffect } from "react";
import styles from "./PostText.module.css";
import cloudinary from "../../utils/cloudinary";

const PostText = ({ text }) => {

  useEffect(() => {
    cloudinary.responsive()
  }, [text])
  
  return (
    <div className={styles.supportingText}>
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};

export default PostText;

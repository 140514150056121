import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { API, graphqlOperation } from "aws-amplify";
import { canViewUnpublishedBlogs } from "../auth/auth-component";
import { listPostsByBlogIdOrderedDesc } from "../../graphql/custom";
import LayoutSpacer from "../layout/LayoutSpacer";
import { TrackedButton } from "../elements/Button";
import Icon from "../elements/Icon";
import styles from "./BlogShowMoreItems.module.css";
import cx from "classnames";
import ButtonType from "../elements/ButtonType";
import ButtonFeel from "../elements/ButtonFeel";
import Navigation from "../elements/Navigation";
import rotateStyles from "../Loading.module.css";
import { blogFetchMore } from "../../actions/posts-actions";
import { useTranslation } from "react-i18next";

export const postPageLimit = 5;

const BlogShowMoreItems = () => {
  const {
    id,
    owner,
    posts: { items, nextToken }
  } = useSelector(s => s.blog);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const loadMoreItems = useCallback(
    async limit => {
      try {
        setIsLoading(true);
        const showUnpublished = await canViewUnpublishedBlogs(owner);
        const queryParams = {
          blogId: id,
          limit: limit,
          nextToken: nextToken,
          filter: showUnpublished ? null : { isPublished: { eq: true } }
        };
        const rawData = await API.graphql(
          graphqlOperation(listPostsByBlogIdOrderedDesc, queryParams)
        );
        dispatch(
          blogFetchMore(
            rawData.data.listPostsByBlogId.items,
            rawData.data.listPostsByBlogId.nextToken
          )
        );
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    },
    [dispatch, id, nextToken, owner]
  );

  useEffect(() => {
    let active = true;

    if (active && nextToken && items.length % postPageLimit > 0) {
      loadMoreItems(postPageLimit - (items.length % postPageLimit));
    }

    return () => (active = false);
  }, [loadMoreItems, nextToken, items.length]);

  const onButtonClick = () => !isLoading && loadMoreItems(postPageLimit);

  if (!nextToken) return null;

  return (
    <Navigation>
      <LayoutSpacer />
      <TrackedButton
        trackingLabel="blog-show-more-items"
        type={ButtonType.Raised}
        feel={ButtonFeel.Accent}
        title={t("pages.blog.showMoreItems.title")}
        className={cx(styles.button, "demo-nav__button")}
        action={onButtonClick}
        // disabled={isLoading}
      >
        <span>{t("pages.blog.showMoreItems.text")}&nbsp;</span>
        <Icon
          icon={isLoading ? "explore" : "keyboard_arrow_down"}
          className={isLoading ? rotateStyles.rotating : ""}
        />
      </TrackedButton>
      <LayoutSpacer />
    </Navigation>
  );
};

export default BlogShowMoreItems;

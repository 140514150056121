import React from "react";
import styles from "./PostInfoEditable.module.css";
import PostTextInput from "./PostTextInput";

const PostInfoEditable = ({ author, publishedAt, onChange }) => {
  return (
    <div className={styles.info}>
      <PostTextInput
        name="author"
        value={author}
        placeholder="Äuthor..."
        onChange={onChange}
      />
      <PostTextInput
        name="publishedAt"
        value={publishedAt}
        placeholder="Date..."
        onChange={onChange}
      />
    </div>
  );
};

export default PostInfoEditable;

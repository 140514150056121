import React from "react";
import Delete from "./actions/Delete";
import LayoutSpacer from "../layout/LayoutSpacer";
import ToggleEditMode from "./actions/ToggleEditMode";
import Save from "./actions/Save";
import UploadImage from "./actions/UploadImage";
import PublishToggle from "./actions/PublishToggle";

const PostActions = ({ post }) => {
  return (
    <div className="post-actions mdl-cell--12-col">
      <LayoutSpacer />
      {post.isEditing ? (
        <>
          <UploadImage post={post} />
          <ToggleEditMode post={post} />
          <Save post={post} />
        </>
      ) : (
        <>
          <Delete post={post} />
          <PublishToggle post={post} />
          <UploadImage post={post} />
          <ToggleEditMode post={post} />
        </>
      )}
    </div>
  );
};

export default PostActions;

import React from "react";
import styles from "./PostMetadata.module.css";

const PostMetadata = ({ children }) => {
  return (
    <div className={`mdl-card__supporting-text ${styles.metadata}`}>
      {children}
    </div>
  );
};

export default PostMetadata;

import React from "react";
import ReactGA from "react-ga";
import cx from "classnames";
import { LOCALE_CZ, LOCALE_EN } from "../i18n";
import { useTranslation } from "react-i18next";
import styles from "./LanguageSelector.module.css";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import useLanguageFromUrl from "../custom-hooks/useLanguageFromUrl";

const getFlagImageStyle = (i18n) => {
    switch (i18n.language) {
        case LOCALE_EN:
            return styles.flagEng;
        case LOCALE_CZ:
        default:
            return styles.flagCze;
    }
}

const changeLanguage = (selectedLanguage, i18n, location, history) => {
    i18n.changeLanguage(selectedLanguage);
    ReactGA.event({
        category: "Interaction",
        action: "Change language",
        label: selectedLanguage,
        nonInteraction: false,
    });
    if (location.pathname.substring(1,3) !== i18n.language) {
        const newPath = "/" + i18n.language + location.pathname.substring(3)
        history.push({ pathname: newPath });
    }
}

const LanguageSelector = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const isSignedIn = useSelector(s => s.user.isSignedIn);
    useLanguageFromUrl();

    return (isSignedIn &&
        <div className={cx("mdl-textfield", "mdl-js-textfield", styles.container)}>
            <span className={cx(styles.flag, getFlagImageStyle(i18n))}></span>
            <select 
                id="selector"
                name="selector"
                className={cx("mdl-textfield__input", styles.selector)}
                onChange={(e) => changeLanguage(e.target.value, i18n, location, history)}
                title={t("site.languageLabel")}
                value={i18n.language}
            >
                <option className={cx(styles.option)} value={LOCALE_CZ}>cz</option>
                <option className={cx(styles.option)} value={LOCALE_EN}>en</option>
            </select>
        </div>
    );
}

export default LanguageSelector;
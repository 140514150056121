import React from "react";
// import CommentCountDisqus from "../comments/comment-count-disqus-component";
import styles from "./PostInfo.module.css";

const PostInfo = ({ author, publishedAt }) => {
  return (
    <div className={styles.info}>
      <strong>{author}</strong>
      <span>{publishedAt}</span>
      {/* <CommentCountDisqus post={post} /> */}
    </div>
  );
};

export default PostInfo;

import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { deletePost, deletePostData } from "../../../graphql/mutations";
import { API } from "aws-amplify";
import { useDispatch } from "react-redux";
import Button from "../../elements/Button";
import Icon from "../../elements/Icon";
import ButtonType from "../../elements/ButtonType";
import Dialog, {
  DialogContent,
  DialogActions,
  showDialog,
  closeDialog
} from "../../elements/Dialog";
import ButtonFeel from "../../elements/ButtonFeel";
import { blogPostDelete } from "../../../actions/posts-actions";

const Delete = ({ post }) => {
  const [redirect, setRedirect] = useState(false);
  const dispatch = useDispatch();
  const id = "delete-post-dialog";

  const deleteCurrentPost = () => {
    try {
      if(post.multilingual && post.multilingual.items) {
        post.multilingual.items.forEach(element => {
          API.graphql({
            query: deletePostData,
            variables: { input: { id: element.id } },
            authMode: "AMAZON_COGNITO_USER_POOLS"
          });          
        });  
      }
      API.graphql({
        query: deletePost,
        variables: { input: { id: post.id } },
        authMode: "AMAZON_COGNITO_USER_POOLS"
      });
      dispatch(blogPostDelete(post.id));
      setRedirect(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {redirect && <Redirect to="/" />}

      <Button
        type={ButtonType.Fab}
        feel={ButtonFeel.Accent}
        title="Delete post"
        action={showDialog(id)}
        inProgress={post.isLoading}
      >
        <Icon icon="delete" />
      </Button>

      <Dialog id={id}>
        <DialogContent>
          <p>
            Are you sure you want to delete this post? This cannot be undone!
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            type={ButtonType.Flat}
            feel={ButtonFeel.Flat}
            title="Cancel action"
            onClick={closeDialog(id)}
          >
            Nope, let me check
          </Button>
          <Button
            type={ButtonType.Raised}
            feel={ButtonFeel.Primary}
            title="Delete this post"
            onClick={deleteCurrentPost}
          >
            Yes, do it
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Delete;

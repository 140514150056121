import React from "react";
import LayoutHeader from "./LayoutHeader";
import LayoutContent from "./LayoutContent";
import LayoutFooter from "./LayoutFooter";
import LayoutSnackbar from "./LayoutSnackbar";
import CookiePolicy from "../CookiePolicy";
import cx from "classnames";
import styles from "./Layout.module.css";

function Layout(props) {
  return (
    <div
      id="layout"
      className={cx("mdl-layout mdl-js-layout mdl-layout--fixed-header", styles.layout)}
    >
      <LayoutHeader />
      <LayoutContent />
      <LayoutFooter />
      <LayoutSnackbar />
      <CookiePolicy />
    </div>
  );
}

export default Layout;

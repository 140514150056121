import { PAGE_SET_TITLE, PAGE_SET_BREADCRUMBS } from "../actions/page-actions";

const pageInitialStage = {
	title: "",
	breadcrumbs: []
};

export default function pageReducer(state = pageInitialStage, action) {
	switch (action.type) {
		case PAGE_SET_TITLE:
			return {
				...state,
				title: action.payload
			};
		case PAGE_SET_BREADCRUMBS:
			return {
				...state,
				breadcrumbs: action.payload
			};
		default:
			return state;
	}
};


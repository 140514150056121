import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import czTranslations from "./i18n/cz.json"
import enTranslations from "./i18n/en.json"

export const LOCALE_CZ = "cz";
export const LOCALE_EN = "en";

const resources = {
  en: { translation: enTranslations },
  cz: { translation: czTranslations }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: LOCALE_CZ,
    fallbackLng: LOCALE_CZ,
    debug: true,
    transEmptyNodeValue: '',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p'],
    interpolation: {
      escapeValue: false
    }
  });

  export default i18n;